import React, { useState } from 'react';
import Select from "react-select";
import { UserBalanceTypeEnum } from '../../../helpers/Enums';
import AllTransactions from './components/AllTransactions';

const options = [
    { value: "", label: "All" },
    { value: UserBalanceTypeEnum.TRANSFER, label: "Transfer" },
    { value: UserBalanceTypeEnum.DEPOSIT, label: "Deposit" },
    { value: UserBalanceTypeEnum.WITHDRAW, label: "Withdraw" },
    { value: UserBalanceTypeEnum.CONVERT, label: "Convert" },
    { value: UserBalanceTypeEnum.SPIN_REWARD, label: "Spin Reward" },
    { value: UserBalanceTypeEnum.GAME_REWARD, label: "Game Reward" },

];


const History = () => {
    const [selectedOption, setSelectedOption] = useState(null)
    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card">
                        <div className="card-header border-0 pb-2 row">
                            <div className="col-xl-3">
                                <h4 className="heading me-2">Transaction History</h4>
                            </div>
                            <div className="col-xl-3">
                                <Select
                                 className="custom-react-select mb-xl-0 mb-3 w-100"
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}

                                    placeholder="Select Filters"
                                    style={{
                                        lineHeight: "40px",
                                        color: "#7e7e7e",
                                        paddingLeft: " 15px",
                                    }}
                                />
                                 
                            </div>
                        </div>
                        <div className="card-body pt-2">
                            <AllTransactions type={selectedOption?.value} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default History;
