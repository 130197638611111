import { CONFIRMED_GET_REWARDS, LOADING_TOGGLE_ACTION, CONFIRMED_EDIT_REWARD_ACTION, FAILED_EDIT_REWARD_ACTION } from "../../actions/rewards/RewardsTypes";


const initialState = {
    rewardsList: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    meta: {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 10,
        total: 0,
    },
};

export function RewardsReducer(state = initialState, actions) {
    if (actions.type === CONFIRMED_GET_REWARDS) {
        return {
            ...state,
            rewardsList: actions.payload.data.rewards,
            showLoading: false,
            meta: actions.payload?.data?.meta,
        };
    }
    if (actions.type === CONFIRMED_EDIT_REWARD_ACTION) {
        const newRewardsList = [...state.rewardsList];
        const rewardIndex = newRewardsList.findIndex(
            (reward) => reward.id === actions.payload.id,
        );

        newRewardsList[rewardIndex] = actions.payload;
        return {
            ...state,
            rewardsList: newRewardsList,
            errorMessage: ''
        };
    }
    if (actions.type === FAILED_EDIT_REWARD_ACTION) {
        return {
            ...state,
            errorMessage: actions.payload,
        };
    }
    if (actions.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: actions.payload,
        };
    }
    return state;
}
