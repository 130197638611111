import axiosInstance from '../services/AxiosInstance';
import { toast } from 'react-toastify'

export function getTokens() {
    return axiosInstance.get(
        `/market/user-balance`,
    );
}
export function getAllTokens() {
    //axios call
    return axiosInstance.get(
        `/market/list-tokens`,
    );
}


export function getTokenFee(tokenId) {
    return axiosInstance.get(
        `/market/token-fee?inputTokenId=${tokenId}`,
    );
}


export function withdrawTokenApi(data) {
    return axiosInstance.post(
        `/market/withdraw-tokens`,
        data
    );
}

export async function getTokenBySymbol(tokenSymbol) {
    //axios call
    try {
        const res = await axiosInstance.get(`/market/user-token-balance/${tokenSymbol}`);
        return res.data.data
    } catch (error) {
        toast.error(error?.response?.data?.message)
        return error
    }
}