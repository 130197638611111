import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { resendOtpAction, resetPasswordAction } from '../../store/actions/AuthActions';
import { OtpTypeEnum, UserAuthErrors } from '../../helpers/Enums';
import { loadingToggleAction } from '../../store/actions/AuthActions';
import { regexValidatePassword } from '../../helpers/Utils';
import { useNavigate } from 'react-router-dom'
const ChangePassword = ({ email, isOtpSent, showLoading }) => {
    const localEmail = localStorage.getItem('email')
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [otp, setOtp] = useState('')
    const [password, setPassword] = useState('');
    const errorsObj = { password: '' };
    const [showPassword, setShowPassword] = useState(false)
    const [errors, setErrors] = useState(errorsObj);

    const onSubmit = (e) => {
        let error = false;
        const errorObj = { ...errorsObj };
        e.preventDefault()
        if (!regexValidatePassword(password)) {
            errorObj.password = UserAuthErrors.PASSWORD_FORMAT;
            error = true;
        }
        setErrors(errorObj);
        if (error) return;
        if (!isOtpSent) {
            dispatch(loadingToggleAction(true));

            dispatch(resendOtpAction(email || localEmail, OtpTypeEnum.REGISTER, false))
        } else {
            dispatch(loadingToggleAction(true));

            dispatch(resetPasswordAction(email || localEmail, password, otp, navigate))
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row main-card">
                        <div className="col-xxl-12 col-lg-12">
                            <div className="row">
                                <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                                    <div className="row justify-content-center h-100 align-items-center">
                                        <div className="col-md-6">
                                            <div className="authincation-content">
                                                <div className="row no-gutters">
                                                    <div className="col-xl-12">
                                                        <div className="auth-form">

                                                            <h4 className="text-center mb-4 ">Change Password</h4>
                                                            <form onSubmit={onSubmit}>
                                                                {
                                                                    isOtpSent &&
                                                                    <div className="form-group mb-3">
                                                                        <label className="">
                                                                            <strong>OTP</strong>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            onChange={(e) => setOtp(e.target.value)}
                                                                        />
                                                                    </div>
                                                                }
                                                                <div className="form-group mb-3">
                                                                    <label className="">
                                                                        <strong>New Password</strong>
                                                                    </label>
                                                                    <div className="input-group">
                                                                        <input
                                                                            value={password}
                                                                            required
                                                                            onChange={(e) =>
                                                                                setPassword(e.target.value)
                                                                            }
                                                                            className="form-control"
                                                                            type={showPassword ? 'text' : 'password'}
                                                                            placeholder="New password"
                                                                        />
                                                                        <span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                                                                            <i className={`fa-solid fa-${showPassword ? 'eye' : 'eye-slash'}`}></i>
                                                                        </span>
                                                                    </div>

                                                                    {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                                                </div>


                                                                <div className="text-center">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-primary btn-block"
                                                                    >
                                                                        {showLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'SUBMIT'}
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        email: state.auth.auth.email,
        isOtpSent: state.auth.otpSent,
        showLoading: state.auth.showLoading
    };
};

export default connect(mapStateToProps)(ChangePassword);