export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const OTP_CONFIRMED_ACTION = '[verify otp action] confirmed otp';
export const RESEND_OTP_CONFIRMED_ACTION = '[verify resend otp action] confirmed resend otp';
export const RESET_PASSWORD_CONFIRMED_ACTION = '[resent password action] confirmed reset password';
export const RESET_PASSWORD_FAILED_ACTION = '[resent password action] failed reset password';
export const RESEND_OTP_FAILED_ACTION = '[verify resend otp action] failed resend otp';
export const OTP_FAILED_ACTION = '[verify otp action] failed otp';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_ZK_CONFRIED_ACTION = '[login action] confirmed zk login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Auth Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const RESET_MSGS = '[Reset action] reset msgs action';