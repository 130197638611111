import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
function WifiConnectionStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  return (
    <div>
      {isOnline ? (
     <p></p>
      ) : (
        <div className="authincation ">
        <div className="container ">
           <div className="row justify-content-center h-100 align-items-center ">
              <div className="col-md-5">
                 <div className="form-input-content text-center error-page">
                    <h1 className="error-text  fw-bold">Offline</h1>
                    <h4>
                       <i className="fa fa-times-circle text-danger" />{" "}
                      No Internet 
                    </h4>
                    <p>Please Check your internet Connection and try again</p>
                    <div>
                       <Link className="btn btn-primary" to="/dashboard">
                          Back to Home
                       </Link>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
      )}
    </div>
  )
}

export default WifiConnectionStatus;
