import { CONFIRMED_GET_TRANSACTIONS, LOADING_TOGGLE_ACTION } from "../../actions/transactions/TransactionTypes";


const initialState = {
    transactionsList: [],
    meta: {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 10,
        total: 0,
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function TransactionsReducer(state = initialState, actions) {
    if (actions.type === CONFIRMED_GET_TRANSACTIONS) {
        return {
            ...state,
            transactionsList: actions.payload?.data,
            meta: actions.payload?.meta,
            showLoading: false
        };
    }

    if (actions.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: actions.payload,
        };
    }
    return state;
}