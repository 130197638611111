import { getAllRewardsApi, updateRewardsStatusApi } from "../../../services/RewardsService";
import { CONFIRMED_EDIT_REWARD_ACTION, CONFIRMED_GET_REWARDS, LOADING_TOGGLE_ACTION, FAILED_EDIT_REWARD_ACTION } from "./RewardsTypes";
import { toastError, toastSuccess } from "../../../services/ToastService";
export function getRewardsAction(filterData) {

    return (dispatch) => {
        getAllRewardsApi(filterData)
            .then((response) => {

                dispatch(
                    {
                        type: CONFIRMED_GET_REWARDS,
                        payload: {
                            ...response.data, meta: {
                                ...response.data.meta,
                                sortBy: filterData?.sortBy
                            }
                        },
                    }
                );
            })
            .catch((error) => {
                dispatch(
                    {
                        type: CONFIRMED_GET_REWARDS,
                        payload: [],
                    }
                );
                toastError(error?.response?.data?.message)
            });

    };
}

export function updateRewardStatusAction(id, status) {
    return (dispatch, state) => {
        updateRewardsStatusApi(id, status)
            .then((response) => {
                toastSuccess(response?.data?.message)
                dispatch(
                    {
                        type: CONFIRMED_EDIT_REWARD_ACTION,
                        payload: response?.data?.data
                    }
                );
            })
            .catch((err) => {
                dispatch(
                    {
                        type: FAILED_EDIT_REWARD_ACTION,
                        payload: err?.response?.data?.message,
                    }
                );
                toastError(err?.response?.data?.message)
            })

            ;

    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}