import React from 'react'

import { Nav, Tab} from 'react-bootstrap';
import DepositForm from './Deposit';
import WithdrawForm from './Withdraw';

const WalletModal = ({ open, setOpen, data }) => {

    return (
        
            <div className="col-xl-12">
					<div className="row">
						<div className="col-xl-12 col-sm-6">
							<div className="card h-auto">
								<div className="card-body px-0 pt-1">
									<Tab.Container defaultActiveKey="Navbuy">
										<div className="">
											<div className="buy-sell">
												<Nav className="nav nav-tabs" eventKey="nav-tab2" role="tablist">
													<Nav.Link as="button" className="nav-link" eventKey="Navbuy" type="button">Withdraw</Nav.Link>
													<Nav.Link as="button" className="nav-link" eventKey="Navsell"  type="button">Deposit</Nav.Link>
												</Nav>
											</div>
											<Tab.Content  >
												<Tab.Pane  eventKey="Navbuy" >
                                                    <div className="sell-element">
															<WithdrawForm />
														</div>		
												</Tab.Pane>
												<Tab.Pane eventKey="Navsell">
                                                        <div className="sell-element">
															<DepositForm />
														</div>
												</Tab.Pane>
											</Tab.Content>
										</div>
									</Tab.Container>	
								</div>
							</div>
						</div>
						
					</div>	
				</div>	
    )
}



export default WalletModal