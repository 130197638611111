import React, { useState } from 'react';
import AllRewardsTable from './AllRewardsTable';
import Select from "react-select";

const sortOptions = [
    { value: 'ASC', label: "Sort by ASC" },
    { value: 'DESC', label: "Sort by DESC" },

];
const Rewards = () => {
    // const [selectedOption, setSelectedOption] = useState(null)
    const [selectedSort, setSelectedSort] = useState(sortOptions[1])

    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card">
                        <div className="card-header border-0 pb-2 row">
                            <div className="col-xl-3">
                                <h4 className="heading me-2">All Rewards </h4>
                            </div>
                            <div className="col-xl-3">
                                <Select
                                 className="custom-react-select mb-xl-0 mb-3 w-100"
                                    defaultValue={selectedSort}
                                    value={selectedSort}
                                    onChange={setSelectedSort}
                                    options={sortOptions}
                                    placeholder="Sort by"
                                    style={{
                                        lineHeight: "40px",
                                        color: "#7e7e7e",
                                        paddingLeft: " 15px",
                                    }}
                                />
                            </div>


                        </div>
                        <div className="card-body pt-2">
                            <AllRewardsTable sortBy={selectedSort?.value} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Rewards;
