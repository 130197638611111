import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <div className="card-header border-0 pb-2 ">
              <div className="row">
                <div className="col-xl-12">
                  <h4 className="heading me-2">Senet Wallet User Policy</h4>
                </div>
                <div className="col-xl-12">
                  <p className=" fs-16">
                    Welcome to Senet Wallet, your gateway to earning and
                    managing SENET TOKEN and Bitcoin SATS rewards through
                    gameplay. At Senet, we prioritize the security, privacy, and
                    satisfaction of our users. This policy outlines the terms
                    and conditions governing the use of Senet Wallet and the
                    associated services provided.
                  </p>
                </div>
                <div className="col-xl-12">
                  <ol>
                    <li className="privacyList">
                      <h5>User Identification:</h5>
                      <p className="fs-16 privacyItem">
                        Senet Wallet operates on a Signup base identification
                        system to uniquely identify users. When creating an
                        account with Senet Wallet, users are required to choose
                        an email and password that will serve as their
                        identifier within the platform. This email & password is
                        used for authentication and to facilitate transactions
                        within the wallet.
                      </p>
                    </li>
                    <li className="privacyList">
                      <h5>Wallet Connection:</h5>
                      <p className="fs-16 privacyItem">
                        Users have the option to connect their Senet Wallet with
                        participating games to withdraw earned rewards
                        seamlessly. By connecting their wallet to the game,
                        users grant permission for the transfer of earned SENET
                        TOKEN and Bitcoin SATS from the game to their Senet
                        Wallet. This connection is established securely to
                        ensure the integrity of the transaction process.
                      </p>
                    </li>
                    <li className="privacyList">
                      <h5>Reward Distribution:</h5>
                      <p className="fs-16 privacyItem">
                        Senet Wallet is designed to distribute SENET TOKEN and
                        Bitcoin SATS as rewards for gameplay activities. Users
                        can earn rewards by actively participating in supported
                        games and completing designated tasks or achievements.
                        Rewards are credited to the user's Senet Wallet in
                        real-time upon completion of qualifying actions within
                        the game.
                      </p>
                    </li>
                    <li className="privacyList">
                      <h5>Withdrawal Process:</h5>
                      <p className="fs-16 privacyItem">
                        Users can initiate withdrawals from their Senet Wallet
                        to transfer earned rewards to external cryptocurrency
                        addresses. The withdrawal process requires users to
                        specify the amount of SENET TOKEN or Bitcoin SATS they
                        wish to withdraw and provide the recipient address for
                        the transfer. Withdrawal requests are processed
                        promptly, subject to verification and security checks.
                      </p>
                      <p className="fs-16 privacyItem">
                        NOTE: Withdraw take maximum 48 hours to complete because
                        of numbers of users withdraw requests.
                      </p>
                    </li>
                    <li className="privacyList">
                      <h5>Security Measures:</h5>
                      <p className="fs-16 privacyItem">
                        Senet Wallet employs robust security measures to
                        safeguard user accounts and funds. This includes
                        encryption protocols, multi-factor authentication, and
                        regular security audits to identify and mitigate
                        potential vulnerabilities. Users are encouraged to take
                        proactive steps to protect their account credentials and
                        personal information.
                      </p>
                    </li>
                    <li className="privacyList">
                      <h5>Privacy Policy:</h5>
                      <p className="fs-16 privacyItem">
                        Senet Wallet is committed to protecting the privacy of
                        its users and adheres to strict privacy practices. User
                        data collected during the registration process and
                        subsequent interactions with the wallet is handled in
                        accordance with our Privacy Policy. We do not share user
                        information with third parties without consent, except
                        as required by law or to facilitate essential wallet
                        functionalities.
                      </p>
                    </li>
                    <li className="privacyList">
                      <h5>Compliance:</h5>
                      <p className="fs-16 privacyItem">
                        Senet Wallet operates in compliance with applicable
                        laws, regulations, and industry standards governing
                        cryptocurrency wallets and related services. We strive
                        to uphold the highest standards of integrity,
                        transparency, and regulatory compliance in all aspects
                        of our operations.
                      </p>
                    </li>
                    <li className="privacyList">
                      <h5>Support and Assistance:</h5>
                      <p className="fs-16 privacyItem">
                        Our dedicated support team is available to assist users
                        with any inquiries, issues, or concerns related to Senet
                        Wallet. Users can contact our support team via the
                        designated channels or{' '}
                        <span className="fw-bold">admin@senetwallet.org</span>{' '}
                        for prompt assistance and resolution of their queries.
                      </p>
                    </li>
                    <li className="privacyList">
                      <h5>Amendments and Updates</h5>
                      <p className="fs-16 privacyItem">
                        Senet Wallet reserves the right to update, modify, or
                        amend this policy at any time to reflect changes in our
                        services, regulatory requirements, or business
                        practices. Users will be notified of any material
                        changes to the policy through the Senet Wallet platform
                        or other communication channels.
                      </p>
                      <p className="fs-16 privacyItem">
                        By using Senet Wallet and its associated services, users
                        agree to abide by the terms and conditions outlined in
                        this policy. We appreciate your trust in Senet Wallet
                        and remain committed to delivering a secure, reliable,
                        and rewarding user experience.
                      </p>
                      <p className="fs-16 privacyItem">
                        For any questions or concerns regarding this policy,
                        please contact our support team at{' '}
                        <span className="fw-bold">admin@senetwallet.org</span>
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
