export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
export const regexValidatePassword = (password) => {
    return passwordRegex.test(password);
}
export const regexValidateUsername = (name) => {
    let username = name.replace(/\s+/g, "").toLowerCase();
    var usernameRegex = /^[a-zA-Z]([._-]?[a-zA-Z0-9]+)*$/g
    return usernameRegex.test(username)
};
export const replaceUnderscore = (text) => {
    return text.replace(/_/g, ' ');
}

export const  displayDecimal=(decimalNumber)=> {
    
    const decimalStr = decimalNumber?.toString();

    
    const [integerPart, decimalPart] = decimalStr?.split('.');

  
    const nonZeroIndex = decimalPart ? decimalPart?.split('').findIndex(digit => digit !== '0') : -1;

   
    const displayedDecimal = nonZeroIndex !== -1
        ? `${integerPart}.${decimalPart?.substring(0, Math.min(nonZeroIndex + 2, decimalPart.length))}`
        : integerPart;

    return displayedDecimal;
}

