import React, { useState } from 'react';
import Select from 'react-select'
import { getNetworkByToken } from '../../../services/NetwokService';
import QRCode from "react-qr-code";
import useWalletAddress from '../../../hooks/useWalletAddress';
import { Link } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import useAllToken from '../../../hooks/useAllToken';
import { toastSuccess } from '../../../services/ToastService';
import { customDropDownMenuStyles } from '../../../helpers/styles';
import { TokensEnum } from '../../../helpers/Enums';

const DepositForm = () => {
    const { allTokens, isTokenLoading, setTokenLoading } = useAllToken()
    const { getWalletAddressByNewtork, walletAddress, generateWalletAddress, isAddressLoading } = useWalletAddress()
    const [selectedToken, setToken] = useState(null)
    const [allNetworks, setAllNetworks] = useState([])
    const [selectedNetwork, setSelectNetwork] = useState(null)

    const onChangeToken = async (token) => {
        setTokenLoading(true)
        setToken(token)
        setSelectNetwork(null)
        const network = await getNetworkByToken(token?.symbol)
        //incase of SNT only network is SUI
        if (token?.symbol === TokensEnum.SNT) {
            const filteredNetwork = network?.filter(network => network?.chainId?.type === TokensEnum.SUI);
            setAllNetworks(filteredNetwork)
        }
        else {
            setAllNetworks(network)
        }
        setTokenLoading(false)
    }

    const onChangeNetwork = async (network) => {
        setSelectNetwork(network)
        getWalletAddressByNewtork(network)
    }

    return (
        <>
            <form>
                <div className="sell-blance">
                    <label className="form-label text-primary">Token</label>
                    <div className="input-group">
                        <Select
                            className="custom-react-select mb-xl-0 mb-3 w-100 z-index-9"
                            options={allTokens}
                            defaultValue={selectedToken}
                            isSearchable={true}
                            placeholder="Select Token"
                            isDisabled={isTokenLoading}
                            isLoading={isTokenLoading}
                            onChange={onChangeToken}
                            closeMenuOnSelect={false}
                            closeMenuOnScroll={false}
                            styles={customDropDownMenuStyles}
                        />
                    </div>
                </div>
                <div className="sell-blance">
                    <label className="form-label text-primary">Network</label>
                    <div className="input-group">
                        <Select
                            className="custom-react-select mb-xl-0 mb-3 w-100"
                            options={allNetworks}
                            defaultValue={selectedNetwork}
                            value={selectedNetwork}
                            isSearchable={true}
                            isDisabled={isTokenLoading}
                            isLoading={isTokenLoading}
                            styles={customDropDownMenuStyles}
                            placeholder="Select Network"
                            onChange={onChangeNetwork}
                        />
                    </div>
                </div>
                <div className="sell-blance text-center my-4">
                    {selectedNetwork && walletAddress && (
                        <>
                            <QRCode value={walletAddress} size="150" />
                            <div className="input-group  form-control my-3 d-flex align-items-center justify-content-center px-3">
                                <span className='oneline-text'> {walletAddress}  </span>
                                <i class="fa-regular fa-copy cursor-pointer link-btn mx-3"
                                    onClick={() => {
                                        copy(walletAddress)
                                        toastSuccess('Copied to clipboard!')
                                    }}
                                ></i>
                            </div>
                        </>
                    )
                    }

                    {
                        selectedNetwork && !walletAddress && (
                            (
                                <Link to={"#"} className="btn btn-primary w-75" isDisabled={isAddressLoading} onClick={() => {
                                    generateWalletAddress(selectedNetwork)
                                }}>
                                    {
                                        isAddressLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Generate Address'
                                    }
                                </Link>
                            )
                        )
                    }

                </div>
            </form>
        </>
    )
}
export default DepositForm;