import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  var d = new Date();
  const navigate=useNavigate()
  return (
    <div className="footer out-footer">
      <div className="copyright">
        <div className="row">
          <div className="col-md-10">
            <p>
              Copyright © Designed &amp; Developed by <span>Crypto Wallet</span>{' '}
              {d.getFullYear()}
            </p>
          </div>
            <div className="col-md-2">
					  <p className="text-end  cursor-pointer" onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
