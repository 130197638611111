export const UserAuthErrors = {
    NOT_VERIFIED: "User is not verified",
    INVALID_USERNAME: "Invalid username",
    USERNAME_CHAR_LIMIT: "Name cannot be greater than 10 characters",
    PASSWORD_FORMAT: "Password must be at least 8 characters long and contain at least 1 lowercase, 1 uppercase, 1 number, and 1 special character"
}

export const OtpTypeEnum = {
    RESEND: 'resend',
    REGISTER: 'register',
}

export const UserBalanceTypeEnum = {
    TRANSFER: 'transfer',
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw',
    CONVERT: 'convert',
    SPIN_REWARD: 'spin_reward',
    GAME_REWARD: 'game_reward',
}
export const UserRoleEnum = {
    NATIVE: 'NATIVE',
    DEVELOPER: 'DEVELOPER',
}

export const UserRewardsStatus = {
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
}

export const TransactionStatusEnum = {
    PROCESSING: 'PROCESSING',
    BROADCAST: 'BROADCAST',
    PENDING: 'PENDING',
    CONFIRM: 'CONFIRM',
    CANCEL: 'CANCEL',
    ERROR: 'ERROR',
}

export const NftPropertiesEnum = {
    CLICKS: 'Clicks',
    IMPRESSIONS: 'Impressions',

}
export const Tabs = {
    TAB0: "tab0",
    TAB1: "tab1",
    TAB2: "tab2",
}
export const Social_Links = {
    GOOGLE: "https://play.google.com/store/apps/details?id=com.wallet.games.reward",
    DISCORD: "https://discord.com/invite/j3A8a7nT",
    TWITTER: "https://twitter.com/senetsystem",
}
export const TokensEnum = {
    SNT: 'SNT',
    SUI: 'sui'
}
export const filterTokens = ['Senet', 'Sui', 'Tether USD'];
