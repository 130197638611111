import React from 'react'
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { checkTransactionType } from '../../../../helpers/Sets';
import { UserBalanceTypeEnum } from '../../../../helpers/Enums';
import TokenInfo from './TokenInfo';
import { replaceUnderscore } from '../../../../helpers/Utils';

const TransactionDetailModal = ({ open, setOpen, data }) => {

    return (
        <Modal className="fade" show={open} centered="true">
            <Modal.Header>
                <Modal.Title>Transaction Detail</Modal.Title>
                <Button
                    onClick={() => setOpen(false)}
                    variant=""
                    className="btn-close"
                ></Button>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {
                        data?.txHash && (
                            <>
                                <Col md="3 "> Tx Hash </Col>
                                <Col md="9" className='mb-3'>
                                    <div className="mb-2 d-flex gap-2 ">
                                        <div className='oneline-text'>
                                            {data?.txHash}
                                        </div>
                                        <i class="fa-regular fa-copy cursor-pointer link-btn"></i>
                                        <i class="fa-solid fa-arrow-up-right-from-square cursor-pointer link-btn"></i>
                                    </div>
                                </Col>
                            </>
                        )
                    }

                    {
                        (data?.type === UserBalanceTypeEnum.CONVERT || data?.type === UserBalanceTypeEnum.TRANSFER) && (
                            <>
                                <Col md="3 "> From </Col>
                                <Col md="9" className='mb-3'>
                                    <TokenInfo
                                        address={data.inputTokenInfo?.address}
                                        logoUri={data?.inputTokenInfo?.logoUri}
                                        inputAmount={data?.inputAmount}
                                        symbol={data?.inputTokenInfo?.symbol}
                                    />
                                </Col>
                                <Col md="3">To</Col>
                                <Col md="9" className='mb-3'>
                                    <TokenInfo
                                        address={data.outputTokenInfo?.address}
                                        logoUri={data?.outputTokenInfo?.logoUri}
                                        inputAmount={data?.outputAmount}
                                        symbol={data?.outputTokenInfo?.symbol}
                                    />
                                </Col>
                            </>
                        )
                    }

                    {
                        checkTransactionType.has(data?.type) && (
                            <>
                                <Col md="3 "> Token Info </Col>
                                <Col md="9" className='mb-3'>
                                    <TokenInfo
                                        address={data.inputTokenInfo?.address}
                                        logoUri={data?.inputTokenInfo?.logoUri}
                                        inputAmount={data?.inputAmount}
                                        symbol={data?.inputTokenInfo?.symbol}
                                    />

                                </Col>
                            </>
                        )
                    }

                    <Col md="3">Type</Col>
                    <Col md="9" className='mb-3'>
                        <p className="mb-0 text-break text-uppercase">{replaceUnderscore(data?.type)}</p>
                    </Col>
                    <Col md="3">Create at</Col>
                    <Col md="9" className='mb-3'>
                        <p className="mb-0">{new Date(data.createdAt).toLocaleString()}</p>
                    </Col>
                    <Col md="3">Status</Col>
                    <Col md="9" className='mb-3'>
                        <p className={`mb-0 ${data.status ? 'text-success' : 'text-danger'}`}>{data.status}</p>
                    </Col>

                </Row>
            </Modal.Body>
        </Modal>
    )
}



export default TransactionDetailModal