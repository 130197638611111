
import { useEffect, useState } from "react";
import { generateWalletAddressApi, getWalletAddressApi } from "../services/WalletService";

const useWalletAddress = () => {

    const [allWalletAddress, setAllWalletAddress] = useState([]);
    const [loading, setLoading] = useState(false);
    const [walletAddress, setWalletAddress] = useState(null);
    
    const fetchAllAddress = async () => {
        try {
            const allToken = await getWalletAddressApi()
            
            setAllWalletAddress(allToken);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    useEffect(() => {
        setLoading(true);
        fetchAllAddress();
    }, []);


    const getWalletAddressByNewtork = (network) => {
        const  data = allWalletAddress.find((address) => address.type === network?.chainId?.type);
        setWalletAddress(data?.address);
    }

    const generateWalletAddress = async (network) => {
        setLoading(true);
        try {
            debugger
            const response = await generateWalletAddressApi(network?.chainId?.chainId)
            console.log({response},response?.data?.data?.wallet.address);
            setWalletAddress(response?.data?.data?.wallet.address);
            setLoading(false);
            fetchAllAddress();

        } catch (error) {
            setLoading(false);
        }
    }
    
    return {
        allWalletAddress,
        walletAddress,
        isAddressLoading: loading,
        setAddressLoading: setLoading,
        getWalletAddressByNewtork,
        generateWalletAddress
    };
}
export default useWalletAddress;