import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import axiosInstance from '../../../services/AxiosInstance';
import { toast } from 'react-toastify';
import useAllToken from '../../../hooks/useAllToken';
import { getTokenBySymbol } from '../../../services/TokensService';
import { getTokensAction } from '../../../store/actions/tokens/TokenActions';
import { displayDecimal } from '../../../helpers/Utils';
import { toastError, toastSuccess } from '../../../services/ToastService';

const Convert = () => {
    const dispatch = useDispatch();
    const { allTokens } = useAllToken()
    const [loading, setLoading] = useState(false)
    const [fromSelectedToken, setFromSelectedToken] = React.useState(allTokens[0] || null)
    const [toSelectedToken, setToSelectedToken] = React.useState(allTokens[1] || null)
    const [transactionCalcData, setTransactionCalcData] = React.useState(null || {
        inputTokenId: 0,
        outputTokenId: 0,
        unitPrice: 0,
        transactionFee: 0
    })
    const [inputTokenValue, setInputTokenValue] = React.useState(0)

    const resetForm = () => {
        setInputTokenValue(0)
        setFromSelectedToken(allTokens[0])
        setToSelectedToken(allTokens[allTokens.length > 1 ? 1 : 0])
    }
    React.useEffect(() => {
        resetForm()
    }, [allTokens])

    React.useEffect(() => {
        const handleChange = async () => {
            let data = {
                inputTokenId: fromSelectedToken?.id,
                outputTokenId: toSelectedToken?.id,
                inputAmount: parseFloat(inputTokenValue) || 0
            }
            try {
                const res = await axiosInstance.get('market/get-quote', {
                    params: data
                })
                setTransactionCalcData(res.data.data)
            } catch (e) { console.log(e) }
        }
        if (fromSelectedToken && toSelectedToken) { handleChange() }

    }, [inputTokenValue, fromSelectedToken, toSelectedToken])

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!toSelectedToken || !fromSelectedToken || !inputTokenValue) {
            toastError("All fields are required")
            return null;
        }
        if (toSelectedToken.id === fromSelectedToken.id) {
            toastError("Tokens cannot be same")
            return null;
        }
        let data = {
            inputTokenId: fromSelectedToken?.id,
            outputTokenId: toSelectedToken?.id,
            inputAmount: inputTokenValue
        }
        setLoading(true)

        try {
            const res = await axiosInstance.post('market/convert', data)
            toastSuccess(res?.data?.message)
            dispatch(getTokensAction())
            setLoading(false)
            resetForm()


        } catch (e) {
            setLoading(false)

            if (Array.isArray(e)) {
                e.response.data.message.map(err => toast.error(err))

            } else {
                toastError(
                    e?.response?.data?.message,

                );
            }
        }
    };

    const setMaxAmount = async (symbol) => {
        const maxAmt = await getTokenBySymbol(symbol)
        setInputTokenValue(maxAmt?.amount ? maxAmt.amount : 0)
    }
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header border-0 pb-0">
                            <h4 className="heading">Convert</h4>
                        </div>
                        <div className="card-body pt-4">
                            <form onSubmit={onSubmit} className='h-100 d-flex justify-content-between flex-column'>
                                <div className="row g-3 mb-3">
                                    <div className="col-xl-12">
                                        <label className="form-label text-primary">From</label>
                                        <div className="input-group">
                                            <input type="text" value={inputTokenValue} className="form-control" onChange={(e) => setInputTokenValue(e.target.value)} />
                                            <button className="btn btn-outline-primary btn-outline-primary " type="button" onClick={() => setMaxAmount(fromSelectedToken?.symbol)}>Max</button >
                                            <Dropdown>
                                                <Dropdown.Toggle className="btn btn-primary btn-outline-primary left-radius">
                                                    {allTokens.length ? <> <img width={20} className='mx-1' src={fromSelectedToken?.logoUri} alt="" />
                                                        {fromSelectedToken?.name}

                                                    </> : ''}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end">
                                                    {
                                                        !allTokens.length ? <Dropdown.Item  > No Tokens Found </Dropdown.Item> : allTokens?.map((token) => (
                                                            <Dropdown.Item key={token?.id}
                                                                onClick={() => {
                                                                    setFromSelectedToken(token)
                                                                    setInputTokenValue(0)
                                                                }}
                                                            >
                                                                <span className={`${token.symbol === "SNT" ? 'bg-secondary mx-1 rounded-lg' : ''}`}>
                                                                    <img src={token?.logoUri} alt="token" className="mx-1" width="30" />
                                                                </span>
                                                                {token?.symbol}
                                                            </Dropdown.Item>

                                                        ))
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>

                                    <div className="col-xl-12">
                                        <label className="form-label text-primary">To</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={transactionCalcData?.outputAmount ? displayDecimal(transactionCalcData?.outputAmount) : 0} readOnly />
                                            <Dropdown>
                                                <Dropdown.Toggle className="btn btn-primary btn-outline-primary left-radius">
                                                    {allTokens.length ? <> <img width={20} className='mx-1' src={toSelectedToken?.logoUri} alt="" />
                                                        {toSelectedToken?.name}
                                                    </> : ''}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end">

                                                    {!allTokens.length ? <Dropdown.Item  > No Tokens Found </Dropdown.Item> :
                                                        allTokens?.map((token) => (
                                                            <Dropdown.Item key={token?.id}
                                                                onClick={() => setToSelectedToken(token)}

                                                            >
                                                                <span className={`${token.symbol === "SNT" ? 'bg-secondary p-1 mx-1 rounded-lg' : ''}`}>
                                                                    <img src={token?.logoUri} alt="token" className="mx-1" width="30" />
                                                                </span>
                                                                {token?.name}</Dropdown.Item>

                                                        ))
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 mt-5">
                                        <div className="d-flex flex-wrap justify-content-between mb-1"><div>Exchange Rate</div><div className="text-muted">1 {fromSelectedToken?.symbol} = {displayDecimal(transactionCalcData?.unitPrice)}{" "}{toSelectedToken?.symbol}</div></div>
                                        <div className="d-flex flex-wrap justify-content-between"><div>Amount</div><div className="text-muted"> ${parseFloat(transactionCalcData?.outputAmount).toFixed(2) || 0}</div></div>
                                        <div className="d-flex flex-wrap justify-content-between"><div>Transaction Fee</div><div className="text-muted"> ${parseFloat(transactionCalcData?.transactionFee).toFixed(2) || 0}</div></div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button type="submit" className="btn mb-5   btn-success py-2 fs-5 text-uppercase px-5">
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Place Order'} </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ tokens }) => {
    const { tokensList, showLoading } = tokens
    return {
        tokensList: tokensList,
        showLoading: showLoading,
    };
};

export default connect(mapStateToProps)(Convert);