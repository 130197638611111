import { useState,useEffect } from 'react';
import Select from 'react-select';
import { sortOptions } from '../Project/Projects';
import Collection from './components/Collection';
import { Tabs } from '../../../helpers/Enums';
import NFTList from './components/NFTList';
import {
  getNFTAction,
  getUsersNFTAction,
} from '../../../store/actions/marketplace/NFTAction';
import { useLocation } from 'react-router-dom';

const MarketPlace = () => {
  const [tab, setTab] = useState(Tabs.TAB0);
  const [collectionId, setCollectionId] = useState(null);
  const [selectedSort, setSelectedSort] = useState(sortOptions[1]);
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.Tab) {
      setTab(location.state.Tab);
    }
  }, [location.state]);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body pt-3  pb-3">
              <div className="coin-warpper d-flex align-items-center justify-content-between flex-wrap">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className={`btn btn-outline-primary ${
                      tab === Tabs.TAB0 ? 'active' : ''
                    }`}
                    onClick={() => {
                      setCollectionId(null);
                      setTab(Tabs.TAB0);
                    }}
                  >
                    Collection
                  </button>
                  <button
                    type="button"
                    className={`btn btn-outline-primary ${
                      tab === Tabs.TAB1 ? 'active' : ''
                    }`}
                    onClick={() => setTab(Tabs.TAB1)}
                  >
                    NFT
                  </button>
                  <button
                    type="button"
                    className={`btn btn-outline-primary ${
                      tab === Tabs.TAB2 ? 'active' : ''
                    }`}
                    onClick={() => setTab(Tabs.TAB2)}
                  >
                    Owned
                  </button>
                </div>
                <div className="d-flex align-items-center justify-content-end  pr-10 flex-wrap">
                  <Select
                    className="custom-react-select mb-xl-0 mb-3 "
                    defaultValue={selectedSort}
                    value={selectedSort}
                    onChange={setSelectedSort}
                    options={sortOptions}
                    placeholder="Sort by"
                    style={{
                      lineHeight: '40px',
                      color: '#7e7e7e',
                      paddingLeft: ' 15px',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tab === Tabs.TAB0 && (
        <Collection
          selectedSort={selectedSort}
          collectionId={collectionId}
          setCollectionId={setCollectionId}
        />
      )}

      {tab === Tabs.TAB1 && (
        <NFTList action={getNFTAction} selectedSort={selectedSort} tab={tab} />
      )}

      {tab === Tabs.TAB2 && (
        <NFTList action={getUsersNFTAction} selectedSort={selectedSort} />
      )}
    </>
  );
};

export default MarketPlace;
