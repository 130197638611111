import { getTransactionApi } from "../../../services/Transaction";
import { CONFIRMED_GET_TRANSACTIONS, LOADING_TOGGLE_ACTION } from "./TransactionTypes";
export function getTransactionAction(txType, filterData) {

    return (dispatch) => {
        getTransactionApi(txType, filterData)
            .then((response) => {
                dispatch(
                    {
                        type: CONFIRMED_GET_TRANSACTIONS,
                        payload: {
                            ...response.data, meta: {
                                ...response.data.meta,
                                type: filterData?.type, sortBy: filterData?.sortBy
                            }
                        },
                    }
                );
            })
            .catch((error) => {
                console.log(error)
                dispatch(
                    {
                        type: CONFIRMED_GET_TRANSACTIONS,
                        payload: {
                            data: [],

                        }
                    }
                );
            });
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}