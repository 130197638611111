
import axiosInstance from '../services/AxiosInstance';

export async function getWalletAddressApi() {
    try {
        const network = await axiosInstance.get(`/user/get-wallet-address`);
        return network.data.data
    } catch (error) {
        return []
    }
}

export const generateWalletAddressApi = (chainId) => {
    return axiosInstance.post(`/user/create-wallet`, { chainId });

}