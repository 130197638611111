import React from 'react'
import NFTList from './NFTList'
import { getCollectionByIdAction } from '../../../../store/actions/marketplace/NFTAction'

const CollectionNftListing = ({ collectionId, selectedSort, collectionName }) => {
  return (
    <div className='row'>
      <div className="d-flex justify-content-between">
        <div className="text-start">
          <h4 className="mb-4">Category Name : <span className="text-primary">{collectionName}</span></h4>
        </div>
       
      </div>
      <NFTList action={getCollectionByIdAction} id={collectionId} selectedSort={selectedSort} />

    </div>
  )
}

export default CollectionNftListing
