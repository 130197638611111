import {
    CONFIRMED_CREATE_PROJECT_ACTION,
    CONFIRMED_DELETE_POST_ACTION,
    CONFIRMED_EDIT_POST_ACTION,
    CONFIRMED_GET_PROJECTS,
    CREATE_PROJECT_ACTION,
    LOADING_TOGGLE_ACTION,
    RESET_MESSAGES_ACTION
} from '../actions/ProjectTypes';

const initialState = {
    projectsList: [],
    meta: {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 10,
        total: 0,
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export default function ProjectsReducer(state = initialState, actions) {
    if (actions.type === CREATE_PROJECT_ACTION) {
        const post = {
            id: Math.random(),
            title: 'Post Title 2asdasd',
            description: 'Sample Description 2asdasdas',
        };

        const posts = [...state.posts];
        posts.push(post);
        return {
            ...state,
            posts,
        };
    }

    if (actions.type === CONFIRMED_DELETE_POST_ACTION) {
        const posts = [...state.posts];
        const postIndex = posts.findIndex(
            (post) => post.id === actions.payload,
        );

        posts.splice(postIndex, 1);

        return {
            ...state,
            posts,
        };
    }

    if (actions.type === CONFIRMED_EDIT_POST_ACTION) {
        const posts = [...state.posts];
        const postIndex = posts.findIndex(
            (post) => post.id === actions.payload.id,
        );

        posts[postIndex] = actions.payload;
        return {
            ...state,
            posts,
            showLoading: false,
        };
    }

    if (actions.type === CONFIRMED_CREATE_PROJECT_ACTION) {
        const newProjList = [...state.projectsList];
        newProjList.push(actions.payload);

        return {
            ...state,
            projectsList: newProjList,
            showLoading: false,
            successMessage: "Project Created Successfully!",
        };
    }

    if (actions.type === CONFIRMED_GET_PROJECTS) {
        return {
            ...state,
            projectsList: actions.payload?.data?.projects,
            meta: actions.payload?.data?.meta,
            showLoading: false
        };
        // return {
        //     ...state,
        //     projectsList: actions.payload,
        //     showLoading: false
        // };
    }
    if (actions.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: actions.payload,
        };
    }
    if (actions.type === RESET_MESSAGES_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
        };
    }
    return state;
}
