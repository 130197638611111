import { useEffect, useState } from 'react';

import MarketPlaceImageViewModal from './MarketPlaceImageViewModal';
import { Link } from 'react-router-dom';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import CollectionNftListing from './CollectionNftListing';
import { getNFTCollectionApi } from '../../../../services/Marketplace';
const Collection = ({ selectedSort, collectionId, setCollectionId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProductImage, setSelectedProductImage] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [collectionListing, setCollectionListing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //api response handling
  const fetchData = async (data) => {
    try {
      setIsLoading(true);
      const response = await getNFTCollectionApi(data);
      setCollectionListing(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setCollectionListing([]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const sortBy = selectedSort?.value;
    fetchData({ sortBy });
  }, [collectionId, selectedSort?.value]);

  const openModal = (image, event) => {
    event.stopPropagation();
    setSelectedProductImage(image);
    setModalOpen(true);
  };
  const onPageChange = (i) => {
    const paginationObj = {
      pageNumber: i,
      pageSize: collectionListing?.meta?.pageSize,
    };
    fetchData(paginationObj);
  };
  return (
    <>
      {collectionId === null ? (
        <>
          <div className="row">
            {isLoading && (
              <div className="text-center">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            )}

            {!isLoading &&
              collectionListing?.adsCollection?.map((item, index) => (
                <div key={index} className="col-xl-4 col-lg-6 col-sm-6">
                  <div
                    className="card cursor-pointer"
                    onClick={() => {
                      setCollectionId(item?.id);
                      setCollectionName(item?.name);
                    }}
                  >
                    <div className="card-body">
                      <div className="new-arrival-product">
                        <div className="new-arrivals-img-contnent">
                          {item?.logoImageHash && (
                            <span
                              className="eye-iconCSS "
                              onClick={(event) =>
                                openModal(item?.logoImageHash, event)
                              }
                            >
                              <i className="bi bi-eye-fill "></i>
                            </span>
                          )}
                          <img
                            className="img-fluid rounded mb-3 customImageCss"
                            src={item?.bannerImageHash}
                            alt=""
                          />
                        </div>
                        <div className="new-arrival-content  mt-3">
                          <h4 className="card-title fs-4 cursor-pointer">
                            {item?.name}
                          </h4>
                          <div class="d-flex justify-content-between">
                            <div class="text-start">
                              <p class="mb-2">
                                Category Name :{' '}
                                <strong class="text-primary">
                                  {item?.category}
                                </strong>
                              </p>
                            </div>
                            <div class="text-end">
                              <p class="mb-2">
                                Total Nft :{' '}
                                <strong class="text-primary">
                                  {item?.noOfAdsNFT}
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div
            id="history_wrapper"
            className="table-responsive dataTablehistory"
          >
            <div className="dataTables_wrapper no-footer">
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                <div className="dataTables_info">
                  Showing {collectionListing?.meta?.pageNumber + 1} to{' '}
                  {collectionListing?.meta?.pageCount} of{' '}
                  {collectionListing?.meta?.pageSize} entries
                </div>

                {collectionListing?.meta?.pageCount > 1 && (
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="application-tbl1_paginate"
                  >
                    <Link
                      className="paginate_button previous"
                      onClick={() => onPageChange(0)}
                    >
                      <i className="fa fa-angle-double-left"></i>
                    </Link>
                    <span>
                      <PaginationControl
                        page={collectionListing?.meta?.pageNumber + 1}
                        between={3}
                        total={collectionListing?.meta?.total}
                        limit={collectionListing?.meta?.pageSize}
                        changePage={(page) => {
                          onPageChange(page - 1);
                        }}
                        ellipsis={1}
                      />
                    </span>
                    <Link
                      className="paginate_button next"
                      onClick={() =>
                        onPageChange(collectionListing?.meta?.pageCount - 1)
                      }
                    >
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <CollectionNftListing
            collectionId={collectionId}
            selectedSort={selectedSort}
            collectionName={collectionName}
          />
        </>
      )}
      <MarketPlaceImageViewModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedProductImage={selectedProductImage}
      />
    </>
  );
};

export default Collection;
