import {
    createPost,
    getPosts,
    deletePost,
    updatePost,
    changeStatsForAutoRewardApi,
} from '../../services/PostsService';
import { toastError, toastSuccess } from '../../services/ToastService';
import {
    CONFIRMED_CREATE_PROJECT_ACTION,
    CONFIRMED_DELETE_POST_ACTION,
    CONFIRMED_EDIT_POST_ACTION,
    CONFIRMED_GET_PROJECTS,
    LOADING_TOGGLE_ACTION,
    RESET_MESSAGES_ACTION
} from './ProjectTypes';

export function deletePostAction(postId, history) {
    return (dispatch) => {
        deletePost(postId).then((response) => {
            dispatch(confirmedDeletePostAction(postId));
            history.push('/postpage');
        });
    };
}

export function confirmedDeletePostAction(postId) {
    return {
        type: CONFIRMED_DELETE_POST_ACTION,
        payload: postId,
    };
}

export function createProjectAction(postData, navigate) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        createPost(postData).then((response) => {
            const singlePost = {
                ...response.data.data.project,
                id: response.data.data.project.id,
            };
            dispatch({
                type: CONFIRMED_CREATE_PROJECT_ACTION,
                payload: singlePost,
            })
            toastSuccess(response?.data?.message)
            navigate('/projects');
        }).catch((error) => {
            toastError(error?.response?.data?.message)
        })
    };
}


export function changeAutoRewardStatusAction(data) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        changeStatsForAutoRewardApi(data).then((response) => {
            const singlePost = {
                ...response.data.data.project,
                id: response.data.data.project.id,
            };
            dispatch({
                type: CONFIRMED_CREATE_PROJECT_ACTION,
                payload: singlePost,
            })
        }).catch((error) => {
            toastError(error?.response?.data?.message)
            dispatch(loadingToggleAction(false));

        });
    };
}

export function getProjectsAction(filterData) {
    return (dispatch) => {
        getPosts(filterData)
            .then((response) => {
                dispatch(
                    {

                        type: CONFIRMED_GET_PROJECTS,
                        payload: {
                            ...response.data, meta: {
                                ...response.data.data.meta,
                                sortBy: filterData?.sortBy,
                            }
                        },
                    }
                );
            })
            .catch((error) => {
                console.log(error)
            });
    };
}



export function confirmedUpdatePostAction(post) {

    return {
        type: CONFIRMED_EDIT_POST_ACTION,
        payload: post,
    };
}

export function updatePostAction(post, history) {
    return (dispatch) => {
        updatePost(post, post.id).then((reponse) => {
            dispatch(confirmedUpdatePostAction(post));
            history.push('/postpage');
        });

    };
}


export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export function resetMessage() {
    return {
        type: RESET_MESSAGES_ACTION,
    };
}