
const NFTCard = ({ item, clicks, openModal, navigate, impressions }) => {
  return (
    <div className="col-xl-4 col-lg-6 col-sm-6">
      <div
        className="card cursor-pointer"
        onClick={() => navigate('/nft-details', { state: { nftDetail: item } })}
      >
        <div className="card-body">
          <div className="new-arrival-product">
            <div className="new-arrivals-img-contnent">
              {item?.previewImageHash && (
                <span
                  className="eye-iconCSS "
                  onClick={(event) => openModal(item?.previewImageHash, event)}
                >
                  <i className="bi bi-eye-fill "></i>
                </span>
              )}
              <img
                className="img-fluid rounded mb-3 customImageCss"
                src={item?.bannerImageHash}
                alt=""
              />
            </div>
            <div className="new-arrival-content  mt-3">
              <h4 className="card-title fs-4 cursor-pointer">{item?.name}</h4>
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <p className="mb-2">
                    Clicks :{' '}
                    <strong className="text-primary">{clicks?.value}</strong>
                  </p>
                </div>
                {item?.is_listed && (
                  <div className="text-end">
                    <p className="mb-2">
                      Price :{' '}
                      <strong className="text-primary">
                        {item?.price}
                        {item?.tokenId?.symbol}
                      </strong>
                    </p>
                  </div>
                )}
              </div>
              <div className="d-flex">
                <div className="text-start">
                  <p className="mb-2">
                    Impressions :{' '}
                    <strong className="text-primary">{impressions?.value}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCard;
