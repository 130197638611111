import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

import Convert from './components/Crypto/Convert';
import History from './pages/History/History';
import Dashboard2 from "./pages/Dashboard/Dashboard2";

import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import ChangePassword from "./pages/ChangePassword";
import Projects from "./pages/Project/Projects";
import Rewards from "./pages/Rewards/Reward";
import MarketPlace from "./pages/MarketPlace/MarketPlace";
import NFTDetails from "./pages/MarketPlace/components/NFTDetails";




const Markup = () => {

  const allroutes = [
    { url: "", component: <Dashboard2 /> },
    { url: "dashboard", component: <Dashboard2 /> },
    { url: "convert", component: <Convert /> },
    { url: "history", component: <History /> },
    { url: "projects", component: <Projects /> },
    { url: "rewards", component: <Rewards /> },
    { url: "marketplace", component: <MarketPlace /> },
    { url: 'nft-details', component: <NFTDetails /> },
    { url: "change-password", component: <ChangePassword /> },


  ];

  return (
    <>
      <Routes>
        <Route path='page-lock-screen' element={<LockScreen />} />
        <Route path='page-error-400' element={<Error400 />} />
        <Route path='page-error-403' element={<Error403 />} />
        <Route path='page-error-404' element={<Error404 />} />
        <Route path='page-error-500' element={<Error500 />} />
        <Route path='page-error-503' element={<Error503 />} />
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
      <ScrollToTop />

    </>
  );
};


export function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )

};

export default Markup;
