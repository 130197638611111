import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { loadingToggleAction } from '../../../../store/actions/marketplace/NFTAction';
import { Link } from 'react-router-dom';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import MarketPlaceImageViewModal from './MarketPlaceImageViewModal';
import { Tabs } from '../../../../helpers/Enums';
import { useNavigate } from 'react-router-dom';
import NFTCard from './NftCard';
import { findImpressionsAndClicks } from './NFTDetails';

const NFTList = ({
  action,
  nftList,
  isLoading,
  meta,
  selectedSort,
  id,
  tab,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProductImage, setSelectedProductImage] = useState('');
  useEffect(() => {
    const sortBy = selectedSort?.value;
    dispatch(loadingToggleAction(true));
    if (id) {
      dispatch(loadingToggleAction(true));
      dispatch(action({ sortBy }, id));
    } else {
      dispatch(loadingToggleAction(true));
      dispatch(action({ sortBy }));
    }
  }, [dispatch, action, selectedSort, id]);

  const openModal = (image, event) => {
    event.stopPropagation();
    setSelectedProductImage(image);
    setModalOpen(true);
  };

  const onPageChange = (i) => {
    dispatch(loadingToggleAction(true));
    dispatch(
      action({
        pageNumber: i,
        pageSize: meta?.pageSize,
      })
    );
  };

  return (
    <>
      <div className="row">
        {isLoading && (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        )}

        {!isLoading &&
          ( nftList?.adsNft?.map((item, index) => {
              if (tab === Tabs.TAB1 && item?.is_listed) {
                  const { clicks, impressions } = findImpressionsAndClicks(
                    item?.properties
                  );

                  return (
                    <NFTCard
                      key={index}
                      item={item}
                      clicks={clicks}
                      impressions={impressions}
                      openModal={openModal}
                      navigate={navigate}
                    />
                  );
                } else if (!tab) {
                  const { clicks, impressions } = findImpressionsAndClicks(
                    item?.properties
                  );

                  return (
                    <NFTCard
                      key={index}
                      item={item}
                      clicks={clicks}
                      impressions={impressions}
                      openModal={openModal}
                      navigate={navigate}
                    />
                  );
                }
                return null;
              }))}
        {nftList?.adsNft?.length === 0 && <h4 className="text-center mt-4">No NFTs found</h4>}
      </div>

      <div id="history_wrapper" className="table-responsive dataTablehistory">
        <div className="dataTables_wrapper no-footer">
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Showing {meta?.pageNumber + 1} to {meta?.pageCount} of{' '}
              {meta?.pageSize} entries
            </div>

            {meta?.pageCount > 1 && (
              <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                id="application-tbl1_paginate"
              >
                <Link
                  className="paginate_button previous"
                  onClick={() => onPageChange(0)}
                >
                  <i className="fa fa-angle-double-left"></i>
                </Link>
                <span>
                  <PaginationControl
                    page={meta?.pageNumber + 1}
                    between={3}
                    total={meta?.total}
                    limit={meta?.pageSize}
                    changePage={(page) => {
                      onPageChange(page - 1);
                    }}
                    ellipsis={1}
                  />
                </span>
                <Link
                  className="paginate_button next"
                  onClick={() => onPageChange(meta?.pageCount - 1)}
                >
                  <i className="fa fa-angle-double-right"></i>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <MarketPlaceImageViewModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedProductImage={selectedProductImage}
      />
    </>
  );
};

const mapStateToProps = ({ marketplace }) => {
  const { nftList, showLoading, meta } = marketplace;
  return {
    nftList,
    isLoading: showLoading,
    meta,
  };
};

export default connect(mapStateToProps)(NFTList);
