
import axiosInstance from './AxiosInstance'

export function getTransactionApi(txType, filterData) {
    let url;
    if (txType) {
        url = `transactions?type=${txType}&sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;
    }
    else {
        url = `transactions?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;
    }

    if (filterData?.pageNumber) {
        url += `&pageNumber=${filterData?.pageNumber}`;
    }
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }
    if (filterData?.type) {
        url += `&type=${filterData?.type}`;
    }
    return axiosInstance.get(
        `${url}`,
    );
}

