import React from 'react'
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { changeAutoRewardStatusAction } from '../../../../store/actions/ProjectsActions';

const ProjectDetailModal = ({ open, setOpen, data }) => {
    const dispatch = useDispatch()
    return (
        <Modal className="fade" show={open} centered="true">
            <Modal.Header>
                <Modal.Title>Project Detail</Modal.Title>
                <Button
                    onClick={() => setOpen(false)}
                    variant=""
                    className="btn-close"
                ></Button>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="3 "> ID</Col>
                    <Col md="9">
                        <p className="mb-0">{data.id}</p>
                    </Col>
                    <Col md="3">Name</Col>
                    <Col md="9">
                        <p className="mb-0">{data.name}</p>
                    </Col>
                    <Col md="3">Description</Col>
                    <Col md="9">
                        <p className="mb-0 text-break">{data.description}</p>
                    </Col>
                    <Col md="3">Create at</Col>
                    <Col md="9">
                        <p className="mb-0">{new Date(data.createdAt).toLocaleString()}</p>
                    </Col>
                    <Col md="3">Status</Col>
                    <Col md="9">
                        <p className={`mb-0 ${data.isActive ? 'text-success' : 'text-danger'}`}>{data.isActive ? 'Active' : 'InActive'}</p>
                    </Col>
                    {
                        data?.website && (
                            <>
                                <Col md="3">Website Url</Col>
                                <Col md="9">
                                    <p className={`mb-0`}>{data?.website}</p>
                                </Col>
                            </>
                        )
                    }
                    <Col md="3">API Key</Col>
                    <Col md="9">
                        <p className="mb-0 w-100  text-break">{data.apiKey}</p>
                    </Col>

                    <Col md="3">Enable Auto Reward </Col>
                    <Col md="9" className='d-flex align-items-center'>
                        <div className="form-check form-switch " data-toggle="tooltip" data-placement="top" title={''}>
                            <input className="form-check-input" checked={data.isRewardApproved} type="checkbox" role="switch"
                                onChange={(e) => {
                                    dispatch(
                                        changeAutoRewardStatusAction({ id: data?.id, status: e.target.checked })
                                    )
                                    data.isRewardApproved = e.target.checked
                                }}
                            />
                        </div>
                    </Col>

                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ProjectDetailModal