import {
  USERS_NFT_MARKETPLACE,
  CONFIRMED_GET_NFT,
  LOADING_TOGGLE_ACTION,
  COLLECTIONBY_ID_NFT_MARKETPLACE,
  BUY_NFT,
  LISTED_NFT,
  EDIT_NFT,
} from '../../actions/marketplace/MarketplaceTypes';

const initialState = {
  nftList: [],
  meta: {
    pageCount: 0,
    pageNumber: 0,
    pageSize: 10,
    total: 0,
  },
  errorMessage: '',
  successMessage: '',
  showLoading: false,
};

export function MarketplaceReducer(state = initialState, action) {
  switch (action.type) {
    case CONFIRMED_GET_NFT:
    case USERS_NFT_MARKETPLACE:
    case COLLECTIONBY_ID_NFT_MARKETPLACE:
      return {
        ...state,
        nftList: action.payload?.data,
        meta: action.payload?.meta,
        showLoading: false,
      };
    

    case EDIT_NFT:
    case LISTED_NFT:
    case BUY_NFT:
      const nftListing = state?.nftList;
      const { adsNft } = nftListing;
      const index = adsNft?.findIndex(item => item.id === action.payload.id);
      nftListing[index] = action.payload;
      return {
        ...state,
        nftList: nftListing,
        showLoading: false,
      };
    case LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: action.payload,
      };
    default:
      return state;
  }
}
