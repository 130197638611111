import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getTransactionAction,
  loadingToggleAction,
} from '../../../../store/actions/transactions/TransactionAction';
import { UserBalanceTypeEnum } from '../../../../helpers/Enums';
import { replaceUnderscore } from '../../../../helpers/Utils';
import TransactionDetailModal from './TransactionDetail';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const AllTransactions = ({ allTransactions, type, loading, meta, sortBy }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getTransactionAction(type, { sortBy }))
  }, [dispatch, type, sortBy]);

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const onPageChange = (i) => {
    dispatch(loadingToggleAction(true));
    dispatch(getTransactionAction(type, {
      pageNumber: i, pageSize: meta?.pageSize
    }))
  };

  return (
    <>
      <div id="history_wrapper" className="table-responsive dataTablehistory">
        <div className="dataTables_wrapper no-footer">
          <table
            id="example"
            className="table shadow-hover dataTable display"
            style={{ minWidth: '845px' }}
          >
            <thead>
              <tr>
                <th>Token</th>
                <th>Type</th>
                <th>Date</th>
                <th>Status</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan="6">
                    <div className="text-center">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </td>
                </tr>
              )}
              {allTransactions?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Link
                      to={'#'}
                      className="market-title d-flex align-items-center"
                    >
                      <div
                        className={`market-icon   d-flex align-items-center justify-content-center ${item?.inputTokenInfo?.symbol === 'SNT' &&
                          'bg-secondary'
                          } `}
                      >
                        <img
                          src={item?.inputTokenInfo?.logoUri}
                          width="30"
                          alt=""
                        />
                      </div>
                      {item?.type === UserBalanceTypeEnum.CONVERT && (
                        <>
                          <i class="fa-solid fa-arrow-right mx-2"></i>
                          <div
                            className={`market-icon  d-flex align-items-center justify-content-center ${item?.outputTokenInfo?.symbol === 'SNT' &&
                              'bg-secondary'
                              } `}
                          >
                            <img
                              src={item?.outputTokenInfo?.logoUri}
                              width="30"
                              alt=""
                            />
                          </div>
                        </>
                      )}
                    </Link>
                  </td>
                  <td className="text-uppercase">
                    {replaceUnderscore(item?.type)}
                  </td>
                  <td>{new Date(item.createdAt).toLocaleString()}</td>
                  <td>{item?.status}</td>

                  <td className="text-end">
                    <div className="d-flex justify-content-end">
                      <Link
                        to={'#'}
                        className="btn btn-primary shadow btn-xs sharp me-3"
                        onClick={() => {
                          setSelectedTransaction(item);
                          setDetailModal(true);
                        }}
                      >
                        <i className="fa-regular fa-eye"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}

              {!loading && allTransactions?.length === 0 && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    No Transaction Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Showing {meta?.pageNumber + 1} to{" "}
              {meta?.pageCount} {" "}
              of {meta?.pageSize} entries
            </div>

            {
              meta?.pageCount > 1 && (
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="application-tbl1_paginate"
                >
                  <Link
                    className="paginate_button previous "
                    to="/history"
                    onClick={() =>
                      onPageChange(0)
                    }
                  >
                    <i className="fa fa-angle-double-left" ></i>
                  </Link>
                  <span>

                    <PaginationControl
                      page={meta?.pageNumber + 1}
                      between={3}
                      total={meta?.total}
                      limit={meta.pageSize}
                      changePage={(page) => {
                        onPageChange(page - 1)
                      }}
                      ellipsis={1}
                    />
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/history"
                    onClick={() =>
                      onPageChange(meta?.pageCount - 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" ></i>
                  </Link>
                </div>
              )
            }

          </div>
        </div>
      </div>
      {selectedTransaction && (
        <TransactionDetailModal
          open={detailModal}
          setOpen={setDetailModal}
          data={selectedTransaction}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { transactionsList, showLoading, meta } = transactions;
  return {
    allTransactions: transactionsList,
    loading: showLoading,
    meta: meta,
  };
};

export default connect(mapStateToProps)(AllTransactions);
