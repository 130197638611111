import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  getProjectsAction,
  loadingToggleAction,
} from '../../../store/actions/ProjectsActions';
import { connect, useDispatch } from 'react-redux';
import ProjectDetailModal from './components/ProjectDetailModal';
import CreateProjectModal from './components/CreateProjectModal';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import Select from 'react-select';

export const sortOptions = [
  { value: 'ASC', label: 'Sort by ASC' },
  { value: 'DESC', label: 'Sort by DESC' },
];
const options = [
  { value: '10', label: '10' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
];
const Projects = ({ allProjects, isLoading, meta }) => {
  const dispatch = useDispatch();

  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSort, setSelectedSort] = useState(sortOptions[1]);
  const [pageSize, setPageSize] = useState(options[0]);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    const sortBy = selectedSort.value;
    dispatch(loadingToggleAction(true));
    dispatch(getProjectsAction({ sortBy, search }));
  }, [dispatch, selectedSort, search]);

  const onPageChange = (i) => {
    dispatch(loadingToggleAction(true));
    dispatch(
      getProjectsAction({
        pageNumber: i,
        pageSize: meta?.pageSize,
      })
    );
  };

  const handleChangePageSize = (e) => {
    setPageSize(e);
    dispatch(loadingToggleAction(true));
    dispatch(
      getProjectsAction({
        pageSize: e.value,
      })
    );
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body pt-3 pb-3">
              <div className="coin-warpper d-flex align-items-center justify-content-between flex-wrap">
                <div>
                  <h4 className="heading mb-0">All Projects</h4>
                </div>

                <div className=" d-flex align-items-center justify-content-between flex-wrap">
                  <button
                    className="btn btn-primary me-2"
                    id="js-programmatic-enable"
                    onClick={() => setCreateProjectModal(true)}
                  >
                    Create Project
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end  pb-5 pr-10 flex-wrap">
          <div className="row justify-content-end">
            <div className="col-12 col-md-6 col-xl-6 ">
              <div className="input-group search-area mb-3">
                <span className="input-group-text">
                  <Link to={'#'}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                        fill="var(--primary)"
                      />
                    </svg>
                  </Link>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-6 ">
              <Select
                className="custom-react-select mb-xl-0 mb-3 "
                defaultValue={selectedSort}
                value={selectedSort}
                onChange={setSelectedSort}
                options={sortOptions}
                placeholder="Sort by"
                style={{
                  lineHeight: '40px',
                  color: '#7e7e7e',
                  paddingLeft: ' 15px',
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="row">
            {isLoading && (
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            )}
            {allProjects?.map((item) => (
              <div
                className="col-xl-3 col-md-6 col-sm-6"
                key={item.id}
                onClick={() => {
                  setSelectedProject(item);
                  setDetailModal(true);
                }}
              >
                <div className="card pull-up">
                  <div className="card-body align-items-center flex-wrap">
                    <div className="d-flex align-items-center mb-4">
                      <Link t={'#'} className="ico-icon">
                        <i className="fa-solid fa-bitcoin-sign"></i>
                      </Link>
                      <div className="ms-4 text-truncate w-70">
                        <Link t={'#'}>
                          <h4 className="text-truncate heading mb-0">
                            {item?.name}
                          </h4>
                        </Link>
                        <span className="text-truncate w-70 ">
                          {item?.description}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="fs-12">{item.type}</span>
                      </div>
                      <div>
                        <p
                          className={`mb-0 fs-14 text-${
                            item.isActive ? 'success' : 'danger'
                          }`}
                        >
                          {item?.isActive ? 'Active' : 'Disabled'}
                        </p>
                        <span className="fs-12">
                          {new Date(item.createdAt).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {allProjects.length === 0 && (
              <div className="text-center fs-18 fw-bold mb-5">
                No Records Found
                </div>
            )}
          </div>
        </div>
      </div>

      <div id="history_wrapper" className=" dataTablehistory ">
        <div className="dataTables_wrapper no-footer">
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Showing {meta?.pageNumber + 1} to {meta?.pageCount} of{' '}
              {meta?.pageSize} entries
            </div>
            <div className="d-flex align-items-center">
              <span className="me-2 fs-14 ">PageSize :</span>
              <Select
                className="custom-react-select "
                defaultValue={pageSize}
                value={pageSize}
                onChange={handleChangePageSize}
                options={options}
                menuPlacement="auto"
                minMenuHeight={300}
                style={{
                  lineHeight: '40px',
                  color: '#7e7e7e',
                  paddingLeft: ' 15px',
                }}
              />
            </div>
            {meta?.pageCount > 1 && (
              <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                id="application-tbl1_paginate"
              >
                <Link
                  className="paginate_button previous"
                  to="/projects"
                  onClick={() => onPageChange(0)}
                >
                  <i className="fa fa-angle-double-left"></i>
                </Link>
                <span>
                  <PaginationControl
                    page={meta?.pageNumber + 1}
                    between={3}
                    total={meta?.total}
                    limit={meta.pageSize}
                    changePage={(page) => {
                      onPageChange(page - 1);
                    }}
                    ellipsis={1}
                  />
                </span>
                <Link
                  className="paginate_button next"
                  to="/projects"
                  onClick={() => onPageChange(meta?.pageCount - 1)}
                >
                  <i className="fa fa-angle-double-right"></i>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {selectedProject && (
        <ProjectDetailModal
          open={detailModal}
          setOpen={setDetailModal}
          data={selectedProject}
        />
      )}

      {createProjectModal && (
        <CreateProjectModal
          open={createProjectModal}
          setOpen={setCreateProjectModal}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ projects }) => {
  const { projectsList, showLoading, errorMessage, successMessage, meta } =
    projects;
  return {
    allProjects: projectsList,
    loading: showLoading,
    errorMessage: errorMessage,
    successMessage: successMessage,
    meta: meta,
  };
};

export default connect(mapStateToProps)(Projects);
