
import axiosInstance from './AxiosInstance'

export function getNFTApi(filterData) {
    let url = `ads-nft?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;


    if (filterData?.pageNumber) {
        url += `&pageNumber=${filterData?.pageNumber}`;
    }
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }

    return axiosInstance.get(
        `${url}`,
    );
}

export function getNFTCollectionApi(filterData) {
    
    let url = `ads-collection?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;


    if (filterData?.pageNumber) {
        url += `&pageNumber=${filterData?.pageNumber}`;
    }
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }

    return axiosInstance.get(
        `${url}`,
    );
}

export function getNFTCollectionByIdApi(filterData,id) {
    let url = `ads-nft/${id}?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;


    if (filterData?.pageNumber) {
        url += `&pageNumber=${filterData?.pageNumber}`;
    }
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }

    return axiosInstance.get(
        `${url}`,
    );
}
export function getUsersNFTApi(filterData) {
    let url = `ads-nft/user/nfts?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;


    if (filterData?.pageNumber) {
        url += `&pageNumber=${filterData?.pageNumber}`;
    }
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }

    return axiosInstance.get(
        `${url}`,
    );
}

export function BuyNFTApi(id) {
    return axiosInstance.post(`ads-nft/buy/${id}`);
}
export function ListNFTApi(id, listedData) {
    return axiosInstance.post(`ads-nft/list/${id}`,listedData);
    
} 
export function updateNFTApi(data, id) {
    return axiosInstance.patch(`ads-nft/update/${id}`, data);
}
