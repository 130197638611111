import axiosInstance from '../services/AxiosInstance';


export function getPosts(filterData) {
    //axios call
    let url = `/project?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;
    if (filterData?.pageNumber) {
        url += `&pageNumber=${filterData?.pageNumber}`;
    }
    if (filterData?.search){
        url+=`&search=${filterData?.search}`
    }
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }
    return axiosInstance.get(
        `${url}`,
    );
    // return axiosInstance.get(
    //     `/project`,
    // );
}

export function createPost(postData) {
    return axiosInstance.post(`project/create`, postData);
}

export function updatePost(post, postId) {
    return axiosInstance.put(`posts/${postId}.json`, post);
}

export function deletePost(postId) {
    return axiosInstance.delete(`posts/${postId}.json`);
}

export function formatPosts(postsData) {
    let posts = [];
    for (let key in postsData) {
        posts.push({ ...postsData[key], id: key });
    }

    return posts;
}

export const changeStatsForAutoRewardApi = (data) => {
    return axiosInstance.get(`/project/rewards/approval/status/${data.id}?isRewardApproved=${data.status}`);
}
export const getProjectByApiKey=(apiKey)=>{
    return axiosInstance.get(`/project/api/${apiKey}`, {
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0',
        }
    });
};