import { UserRoleEnum } from "../../../helpers/Enums";

export const MenuList = [
    {
        title: 'Dashboard',
        iconStyle: <i className="material-icons">grid_view</i>,
        to: 'dashboard',
        role: [UserRoleEnum.NATIVE, UserRoleEnum.DEVELOPER]
    },
    // {
    //     title: 'Convert',
    //     iconStyle: <i className="material-symbols-outlined">swap_vert</i>,
    //     to: 'convert',
    //     role: [UserRoleEnum.NATIVE, UserRoleEnum.DEVELOPER]
    // },
    {
        title: 'History',
        iconStyle: <i className="material-icons">description</i>,
        to: 'history',
        role: [UserRoleEnum.NATIVE, UserRoleEnum.DEVELOPER]
    },
    {
        title: 'Projects',
        iconStyle: <i className="material-icons">table_chart</i>,
        to: '/projects',
        role: [UserRoleEnum.DEVELOPER]
    },
    {
        title: 'Rewards',
        iconStyle: <i className="material-icons">table_chart</i>,
        to: 'rewards',
        role: [UserRoleEnum.DEVELOPER]
    },
    {
        title: 'MarketPlace',
        iconStyle: <i className="material-icons">table_chart</i>,
        to: 'marketplace',
        role: [UserRoleEnum.NATIVE, UserRoleEnum.DEVELOPER],
    },

]