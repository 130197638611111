import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { createProjectAction, resetMessage } from '../../../../store/actions/ProjectsActions';
import { useNavigate } from 'react-router-dom'
const CreateProjectModal = ({ open, setOpen, success, loading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dataObj = { name: '', description: '', websiteUrl: '', enableAutoReward: false };
    const [formData, setFormData] = useState(dataObj);
    const [errors, setErrors] = useState(dataObj);

    const handleSubmit = (e) => {
        e.preventDefault()
        let isValid = true;
        let errorsObj = { ...errors };
        if (formData?.name === '') {
            errorsObj.name = 'Name is Required';
            isValid = false;
        }
        if (formData?.description === '') {
            errorsObj.description = 'Description is Required';
            isValid = false;
        }
        setErrors(errorsObj);

        if (!isValid) {
            return;
        }
        let data = {
            name: formData?.name,
            description: formData?.description,
            website: formData?.websiteUrl,
            isRewardApproved: formData?.enableAutoReward

        }
        dispatch(createProjectAction(data, navigate))
    }

    useEffect(() => {
        if (success) {
            setOpen(false)
        }
        return () => { dispatch(resetMessage()) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success])

    return (
        <Modal className="fade" show={open} centered="true">
            <Modal.Header>
                <Modal.Title>Create New Project</Modal.Title>
                <Button
                    onClick={() => setOpen(false)}
                    variant=""
                    className="btn-close"
                ></Button>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="row g-3 mb-3">
                        <div className="col-xl-12">
                            <label className="form-label">Name
                                <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                                <input type="text" className="form-control w-100" onChange={(e) => setFormData({
                                    ...formData,
                                    name: e.target.value
                                })} />
                            </div>
                            {errors.name && <div className="text-danger fs-12">{errors.name}</div>}

                        </div>

                        <div className="col-xl-12">
                            <label className="form-label">Description
                                <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                                <textarea
                                    className="form-txtarea form-control"
                                    rows="3"
                                    id="comment"
                                    onChange={(e) => setFormData({
                                        ...formData,
                                        description: e.target.value
                                    })}
                                ></textarea>
                            </div>
                            {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
                        </div>

                        <div className="col-xl-12">
                            <label className="form-label">Website url
                            </label>
                            <div className="input-group">
                                <input type="text" className="form-control w-100" onChange={(e) => setFormData({
                                    ...formData,
                                    websiteUrl: e.target.value
                                })} />
                            </div>
                        </div>
                        <div className="col-xl-12 d-flex align-items-start gap-3">
                            <label className="form-label mt-1"> Enable Auto Reward
                            </label>
                            <div className="form-check form-switch " data-toggle="tooltip" data-placement="top" title={''}>
                                <input className="form-check-input" checked={formData.enableAutoReward} type="checkbox" role="switch"
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            enableAutoReward: e.target.checked
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="row g-3 text-center">
                        <div className="col-xl-12">
                            <button
                                type="submit"
                                className="btn flex-fill btn-primary py-2 fs-5 text-uppercase px-5"
                                disabled={loading}
                            >
                                {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Create Project'}
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = ({ projects }) => {
    const { projectsList, successMessage, showLoading } = projects
    return {
        allProjects: projectsList,
        success: successMessage,
        loading: showLoading

    };
};

export default connect(mapStateToProps)(CreateProjectModal);

