import { applyMiddleware, combineReducers, compose, createStore, } from 'redux';
import ProjectsReducer from './reducers/ProjectsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import { TransactionsReducer } from './reducers/transactions/TransactionReducer';
import { RewardsReducer } from './reducers/rewards/RewardsReducer';
import TokensReducer from './reducers/tokens/TokensReducer';
//import { reducer as reduxFormReducer } from 'redux-form';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { MarketplaceReducer } from './reducers/marketplace/MarketplaceReducer';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const authTransform = {
    in: (state) => ({
        auth: state.auth,
    }),
    out: (state) => state,
};
const persistConfig = {
    key: 'root',
    transforms: [authTransform],
    whitelist: ['auth'],
    storage,
    version: 1,
};
const reducers = combineReducers({
    transactions: TransactionsReducer,
    rewards: RewardsReducer,
    projects: ProjectsReducer,
    auth: AuthReducer,
    tokens: TokensReducer,
    marketplace: MarketplaceReducer,
    todoReducers,
    //form: reduxFormReducer,	

});
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, composeEnhancers(middleware));

export const persistor = persistStore(store);
//const store = createStore(rootReducers);

// export const store = createStore(reducers, composeEnhancers(middleware));
