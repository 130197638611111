import axiosInstance from '../services/AxiosInstance';


export async function getNetworkByToken(tokenSymbol) {
    //axios call
    try {
        const network = await axiosInstance.get(`/market/token-network?symbol=${tokenSymbol}`);
        return network.data.data.map((network) => ({
            ...network,
            label: <div className='d-flex align-items-center gap-3'><img src={network?.chainId?.logoUri} height="30px" width="30px" alt='' />{network?.chainId?.name} </div>,
        }));
    } catch (error) {
        return []
    }
}

