import { lazy, Suspense } from 'react';

/// Components
import  { MainLayout } from './jsx';
import { connect } from 'react-redux';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from 'react-router-dom';
// action
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import './css/style.css';
import ChangePassword from './jsx/pages/ChangePassword';
import Error400 from './jsx/pages/Error400';
import Dashboard2 from './jsx/pages/Dashboard/Dashboard2';
import Convert from './jsx/components/Crypto/Convert';
import History from './jsx/pages/History/History';
import Projects from './jsx/pages/Project/Projects';
import Rewards from './jsx/pages/Rewards/Reward';
import MarketPlace from './jsx/pages/MarketPlace/MarketPlace';
import NFTDetails from './jsx/pages/MarketPlace/components/NFTDetails';
import LockScreen from './jsx/pages/LockScreen';
import Error403 from './jsx/pages/Error403';
import Error404 from './jsx/pages/Error404';
import Error500 from './jsx/pages/Error500';
import Error503 from './jsx/pages/Error503';
import PrivacyPolicy from './jsx/pages/PrivacyPolicy/PrivacyPolicy';
// import "react-toastify/dist/ReactToastify.css";

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const OTP = lazy(() => import('./jsx/pages/OTP'));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {


  const routeblog = (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<OTP />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route element={<MainLayout />} >

      <Route
        path=""
        element={
          <Protected isAuthenticated={props.isAuthenticated}>
            <Dashboard2 />
          </Protected>
        }
      />
      <Route
        path="dashboard"
        element={
          <Protected isAuthenticated={props.isAuthenticated}>
            <Dashboard2 />
          </Protected>
        }
      />
      <Route
        path="convert"
        element={
          <Protected isAuthenticated={props.isAuthenticated}>
            <Convert />
          </Protected>
        }
      />

      <Route
        path="history"
        element={
          <Protected isAuthenticated={props.isAuthenticated}>
            <History />
          </Protected>
        }
      />
      <Route
        path="projects"
        element={
          <Protected isAuthenticated={props.isAuthenticated}>
            <Projects />
          </Protected>
        }
      />
      <Route
        path="rewards"
        element={
          <Protected isAuthenticated={props.isAuthenticated}>
            <Rewards />
          </Protected>
        }
      />
      <Route
        path="marketplace"
        element={
          <Protected isAuthenticated={props.isAuthenticated}>
            <MarketPlace />
          </Protected>
        }
      />
      
      <Route
        path="nft-details"
        element={
          <Protected isAuthenticated={props.isAuthenticated}>
            <NFTDetails />
          </Protected>
        }
      />
      <Route
        path="change-password"
        element={
          <Protected isAuthenticated={props.isAuthenticated}>
            <ChangePassword />
          </Protected>
        }
      />   
      </Route>

      <Route path="page-lock-screen" element={<LockScreen />} />
      <Route path="page-error-400" element={<Error400 />} />
      <Route path="page-error-403" element={<Error403 />} />
      <Route path="page-error-404" element={<Error404 />} />
      <Route path="page-error-500" element={<Error500 />} />
      <Route path="page-error-503" element={<Error503 />} />
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />

      
    </Routes>
  );
  return (
    <>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {routeblog}
      </Suspense>
    </>
  );
}

const Protected = ({ children, isAuthenticated }) => {
  if (!isAuthenticated) {
    return <Navigate to="/login"  />;
  }
  return children;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

//export default connect((mapStateToProps)(App));
export default withRouter(connect(mapStateToProps)(App));
