export const CREATE_PROJECT_ACTION = '[Post Action] Create Post';
export const CONFIRMED_CREATE_PROJECT_ACTION =
    '[Post Action] Confirmed Create Post';
export const GET_POSTS = '[Post Action] Get Posts';
export const CONFIRMED_GET_PROJECTS = '[Post Action] Confirmed Get Posts';
export const EDIT_POST_ACTION = '[Post Action] Edit Post';
export const CONFIRMED_EDIT_POST_ACTION =
    '[Post Action] Confirmed Edit Post';
export const CONFIRMED_DELETE_POST_ACTION =
    '[Post Action] Confirmed Delete Post';

export const LOADING_TOGGLE_ACTION = '[Project Loading action] toggle loading';


export const RESET_MESSAGES_ACTION = '[Message Action] Reset Messages';
