import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { toastError } from '../../../../services/ToastService';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateNFTAction } from '../../../../store/actions/marketplace/NFTAction';
const EditNFTDetail = ({ openEditModal, setOpenEditModal, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.marketplace.showLoading);
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState(null);
  const [externalLink, setExternalLink] = useState(null);
  const [externalLinkError, setExternalLinkError] = useState('');

  const EditDetails = async () => {
    if (!description || !selectedFile || !externalLink) {
      toastError('All fields required.');
      return;
    }
    if (!urlPattern.test(externalLink)) {
      setExternalLinkError('Enter a valid URL.');
      return;
    }

    setExternalLinkError('');
    const bodyFormData = new FormData();
    bodyFormData.append('externalLink', externalLink);
    bodyFormData.append('description', description);

    if (selectedFile) {
      bodyFormData.append('bannerImage', selectedFile);
    }

    dispatch(updateNFTAction(id, bodyFormData, navigate));
  };

  return (
    <Modal className="fade" show={openEditModal} centered="true">
      <Modal.Header>
        <Modal.Title>Edit Nft Detail</Modal.Title>
        <Button
          onClick={() => setOpenEditModal(false)}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="row g-3 mb-3">
            <div className="col-xl-12">
              <label className="form-label">
                Banner Image
                <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  type="file"
                  className="form-control w-100"
                  accept="image/*"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
              </div>
            </div>
            <div className="col-xl-12">
              <label className="form-label">
                Description
                <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control w-100"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="col-xl-12">
              <label className="form-label">
                External Link
                <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control w-100"
                  onChange={(e) => {
                    setExternalLink(e.target.value);
                    setExternalLinkError('');
                  }}
                />
              </div>
              {externalLinkError && (
                <div className="text-danger fs-14 mt-1">
                  {externalLinkError}
                </div>
              )}
            </div>
          </div>

          <div className="row g-3 text-center">
            <div className="col-xl-12">
              <button
                type="button"
                className="btn flex-fill btn-primary py-2 fs-5 text-uppercase px-5"
                onClick={EditDetails}
              >
                {isLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  'Update NFT'
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditNFTDetail;
