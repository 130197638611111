import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select'
import useAllToken from '../../../hooks/useAllToken';
import { getNetworkByToken } from '../../../services/NetwokService';
import { withdrawTokenApi } from '../../../services/TokensService';
import { toastError, toastSuccess } from '../../../services/ToastService';
import { customDropDownMenuStyles } from '../../../helpers/styles';
import { TokensEnum } from '../../../helpers/Enums';


const WithdrawForm = () => {
	const { allTokens, isTokenLoading, setTokenLoading } = useAllToken(false)
	const [allNetworks, setAllNetworks] = useState([])
	const [selectedNetwork, setSelectNetwork] = useState(null)
	const [selectedToken, setToken] = useState(null)
	const [amount, setAmount] = useState(0)
	const [address, setAddress] = useState('')
	const [loading, setLoading] = useState(false)

	const onChangeToken = async (token) => {
		setTokenLoading(true)
		setToken(token)
		setSelectNetwork(null)
		const network = await getNetworkByToken(token?.symbol)
		//incase of SNT only network is SUI
		if (token?.symbol === TokensEnum.SNT) {
			const filteredNetwork = network?.filter(network => network?.chainId?.type === TokensEnum.SUI);
			setAllNetworks(filteredNetwork)
		}
		else {
			setAllNetworks(network)
		}
		setTokenLoading(false)
	}

	const onChangeNetwork = async (network) => {
		setSelectNetwork(network)
	}

	const withdrawToken = async () => {
		setLoading(true)
		if (!selectedToken || !selectedNetwork || !address || amount <= 0) {
			toastError('All fields required.')
			setLoading(false)
			return
		}
		try {
			const data = {
				toAddress: address,
				amount: amount,
				tokenId: selectedNetwork?.id,
			}
			const res = await withdrawTokenApi(data)
			setLoading(false)
			setSelectNetwork(null)
			setToken(null)
			setAmount(0)
			setAddress('')
			toastSuccess(res?.data?.message)
		} catch (error) {
			setLoading(false)
			toastError(error?.response?.data?.message)
		}
	}
	return (
		<>
			<form>
				<div className="sell-blance">
					<label className="form-label text-primary">Token</label>
					<div className="input-group">
						<Select
							className="custom-react-select mb-xl-0 mb-3 w-100"
							options={allTokens}
							defaultValue={selectedToken}
							isSearchable={true}
							placeholder="Select Token"
							isDisabled={isTokenLoading}
							isLoading={isTokenLoading}
							onChange={onChangeToken}
							styles={customDropDownMenuStyles}
						/>
					</div>
				</div>
				<div className="sell-blance">
					<label className="form-label text-primary">Network</label>
					<div className="input-group">
						<Select
							className="custom-react-select mb-xl-0 mb-3 w-100"
							options={allNetworks}
							defaultValue={selectedNetwork}
							value={selectedNetwork}
							isSearchable={true}
							isDisabled={isTokenLoading}
							isLoading={isTokenLoading}
							placeholder="Select Network"
							onChange={onChangeNetwork}
							styles={customDropDownMenuStyles}
						/>
					</div>
				</div>
				<div className="sell-blance">
					<label className="form-label text-primary">Address</label>
					<div className="input-group">
						<input type="text" className="form-control" placeholder="Address" value={address}
							onChange={(e) => {
								setAddress(e.target.value)
							}}
						/>
					</div>
				</div>
				<div className="sell-blance">
					<label className="form-label text-primary">Amount</label>
					<div className="form-label blance"><span>BALANCE:</span><p className="mb-0">{selectedToken ? selectedToken?.amount : '0'}</p></div>
					<div className="input-group">
						<input type="text" className="form-control custom-form-control" placeholder="Amount" value={amount}
							onChange={
								(e) => {
									const onlyNumbers = /^[0-9]*[.]?[0-9]*$/
									if (!onlyNumbers.test(e.target.value)) return
									setAmount(e.target.value)
								}
							} />
						<span className="input-group-text cursor-pointer"
							onClick={() => setAmount(selectedToken?.amount)}>Max</span>
					</div>
				</div>

				<div className="text-center mt-4">
					<Link to={"#"} className="btn btn-primary w-75" onClick={withdrawToken} isDisabled={loading}>
						{
							loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Withdraw'
						}
					</Link>
				</div>

			</form>
		</>
	)
}
export default WithdrawForm;