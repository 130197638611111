import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import MarketPlaceImageViewModal from './MarketPlaceImageViewModal';
import { NftPropertiesEnum } from '../../../../helpers/Enums';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import NFTListing from './NFTListing';
import { useDispatch } from 'react-redux';
import { BuyNFTAction, ListNFTAction } from '../../../../store/actions/marketplace/NFTAction';
import EditNFTDetail from './EditNFTDetail';

const NFTDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openTokenModal, setOpenTokenModal] = useState(false);

  const tokenDetailsString = localStorage.getItem('userDetails');
  const userDetails = JSON.parse(tokenDetailsString);
  const userId = userDetails?.user?.id;

  const { state } = useLocation();
  const { nftDetail } = state;
  const [selectedProductImage, setSelectedProductImage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  if (!nftDetail) {
    // Redirect to login page if nftDetail doesn't exist
    return <Link to="/login" />;
  }

  const { clicks, impressions } = findImpressionsAndClicks(
    nftDetail?.properties
  );
  const openModal = (image) => {
    setSelectedProductImage(image);
    setModalOpen(true);
  };
  const handleBuyNft = async () => {
    dispatch(BuyNFTAction(nftDetail?.id, navigate));
  };
  return (
    <>
      <div className="container-fluid mh-auto">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-6">
                    <div>
                      {nftDetail?.previewImageHash && (
                        <span
                          className="eye-iconCSS"
                          style={{ top: '15%', left: '3%' }}
                          onClick={() => openModal(nftDetail?.previewImageHash)}
                        >
                          <i className="bi bi-eye-fill "></i>
                        </span>
                      )}
                      <img
                        className="img-fluid rounded mb-3"
                        src={nftDetail?.bannerImageHash}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-4 col-md-4 col-sm-12">
                    <div className="product-detail-content">
                      <div className="new-arrival-content pr">
                        <h4 className="mb-2">{nftDetail?.name}</h4>
                        <div className="d-flex ">
                          <h4 className="  fw-bold">Category Name : </h4>
                          <h4 className=" float-start d-block ms-2 text-primary ">
                            {nftDetail?.collectionId?.name}
                          </h4>
                        </div>
                        <div className="d-flex ">
                          <h4 className=" fw-bold">
                            Price :{' '}
                            <span className="text-primary">
                              {nftDetail?.price} {nftDetail?.tokenId?.symbol}
                            </span>
                          </h4>
                        </div>
                        <p className="mb-2">
                          <span className="fw-bold">Clicks : </span>
                          <span className="item">{clicks?.value}</span>
                        </p>
                        <p className="mb-2">
                          <span className="fw-bold">Impressions : </span>
                          <span className="item">{impressions?.value}</span>
                        </p>

                        <h4 className="text-content fw-bold m-0">
                          Description
                        </h4>
                        <p className="text-content m-1">
                          {nftDetail?.description}
                        </p>
                        <div>
                          <h4>External Link : </h4>
                          <Link
                            to={nftDetail?.externalLink}
                            className="text-primary ms-1"
                          >
                            {nftDetail?.externalLink}
                          </Link>
                        </div>

                        {userId !== nftDetail?.userId?.id && (
                          <div className="d-flex align-items-end flex-wrap mb-2">
                            <div className="shopping-cart  mb-1 mt-3 ">
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  swal({
                                    title: 'Are you sure?',
                                    text: 'You want to buy this nft?',
                                    buttons: ['Cancel', 'Accept'],
                                  }).then((wilAccept) => {
                                    if (wilAccept) {
                                      handleBuyNft();
                                    }
                                  });
                                }}
                              >
                                <i className="fa fa-shopping-basket me-2"></i>
                                Buy Now
                              </button>
                            </div>
                          </div>
                        )}

                        {
                          userId === nftDetail?.userId?.id && (
                            <div className="d-flex align-items-end flex-wrap mb-2">
                              <div className="shopping-cart  mb-1 mt-3 ">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setOpenTokenModal(!nftDetail?.is_listed)
                                  if(nftDetail?.is_listed){
                                    dispatch(ListNFTAction(nftDetail?.id, navigate))
                                  }
                                  }}
                                >
                                  {nftDetail?.is_listed?"Unlist Nft":"List Nft"}
                                </button>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {userId === nftDetail?.userId?.id && (
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setOpenEditModal(true);
                        }}
                      >
                        <i class="bi bi-pencil-square me-2"></i>
                        Edit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <MarketPlaceImageViewModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          selectedProductImage={selectedProductImage}
        />
      )}
      {openTokenModal && (
        <NFTListing
          id={nftDetail?.id}
          openTokenModal={openTokenModal}
          setOpenTokenModal={setOpenTokenModal}
        />
      )}
      {openEditModal && (
        <EditNFTDetail
          id={nftDetail?.id}
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
        />
      )}
    </>
  );
};

export default NFTDetails;
export const findImpressionsAndClicks = (properties) => {
  const nftProperties = properties && JSON.parse(properties);
  const impressions =
    nftProperties &&
    nftProperties.find(
      (obj) => obj?.trait_type === NftPropertiesEnum.IMPRESSIONS
    );
  const clicks =
    nftProperties &&
    nftProperties.find((obj) => obj?.trait_type === NftPropertiesEnum.CLICKS);

  return { impressions, clicks };
};
