import axios from 'axios';
import { store } from '../store/store';
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,

});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  config.params = config.params || {};
  if (token) {
    config.headers.authorization = token;
  }

  config.params = config.params || {};
  return config;
});


axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const status = error.response?.status || 200
    if (status === 401) {
      localStorage.clear()
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

export default axiosInstance;
