import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { replaceUnderscore } from '../../../helpers/Utils';
import {
    getRewardsAction,
    loadingToggleAction,
    updateRewardStatusAction,
} from '../../../store/actions/rewards/RewardsAction';
import {
    TransactionStatusEnum,
    UserRewardsStatus,
} from '../../../helpers/Enums';
import swal from 'sweetalert';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const AllRewardsTable = ({ allRewards, loading, meta, sortBy }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadingToggleAction(true));
        dispatch(getRewardsAction({ sortBy }));
    }, [dispatch, sortBy]);

    const onPageChange = (i) => {
        dispatch(loadingToggleAction(true));
        dispatch(getRewardsAction({
            pageNumber: i, pageSize: meta?.pageSize
        }))
    };

    return (
        <>
            <div id="history_wrapper" className="table-responsive dataTablehistory">
                <div className="dataTables_wrapper no-footer">
                    <table
                        id="example"
                        className="table shadow-hover dataTable display"
                        style={{ minWidth: '845px' }}
                    >
                        <thead>
                            <tr>
                                <th>Project Name</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th className="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td colSpan="6">
                                        <div className="text-center">
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        </div>
                                    </td>
                                </tr>
                            )}

                            {allRewards?.map((item, index) => (

                                <tr key={index} className={(parseFloat(item?.amount) * parseFloat(item?.token?.usdValue)) >= 0.5 ? 'green' : 'transparent'}>
                                    <td>{item?.project?.name}</td>
                                    <td >
                                        <img
                                            src={item?.token?.logoUri}
                                            alt=""
                                            className="rounded-circle me-2 bg-primary"
                                            width="25"
                                            height="25"
                                        />
                                        {item?.amount}
                                    </td>
                                    <td className="text-uppercase">
                                        {replaceUnderscore(item?.type)}
                                    </td>
                                    <td>{new Date(item.createdAt).toLocaleString()}</td>
                                    <td>{item?.status}</td>

                                    <td className="text-end">
                                        {item?.status === TransactionStatusEnum.PENDING && (
                                            <div className="d-flex justify-content-end">
                                                <span
                                                    className="btn btn-primary shadow btn-xs sharp me-3"
                                                    onClick={() => {
                                                        swal({
                                                            title: 'Are you sure?',
                                                            text: 'Once accepted, you will not be able to revert this!',
                                                            icon: 'warning',
                                                            buttons: ['Cancel', 'Accept'],
                                                        }).then((willDelete) => {
                                                            if (willDelete) {
                                                                dispatch(
                                                                    updateRewardStatusAction(
                                                                        item.id,
                                                                        UserRewardsStatus.ACCEPTED
                                                                    )
                                                                );
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <i className="fa-solid fa-check"></i>
                                                </span>
                                                <span
                                                    className="btn btn-danger shadow btn-xs sharp me-3"
                                                    onClick={() => {
                                                        swal({
                                                            title: 'Are you sure?',
                                                            text: 'Once rejected, you will not be able to revert this!',
                                                            icon: 'warning',
                                                            dangerMode: true,
                                                            buttons: ['Cancel', 'Reject'],
                                                        }).then((willDelete) => {
                                                            if (willDelete) {
                                                                dispatch(
                                                                    updateRewardStatusAction(
                                                                        item.id,
                                                                        UserRewardsStatus.REJECTED
                                                                    )
                                                                );
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <i className="fa-solid fa-xmark"></i>
                                                </span>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            {!loading && allRewards.length === 0 && (
                                <tr>
                                    <td colSpan={6} style={{ textAlign: 'center' }}>
                                        No Requests Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                        <div className="dataTables_info">
                            Showing {meta?.pageNumber + 1} to{" "}
                            {meta?.pageCount} {" "}
                            of {meta?.pageSize} entries
                        </div>

                        {
                            meta?.pageCount > 1 && (
                                <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="application-tbl1_paginate"
                                >
                                    <Link
                                        className="paginate_button previous "
                                        to="/rewards"
                                        onClick={() =>
                                            onPageChange(0)
                                        }
                                    >
                                        <i className="fa fa-angle-double-left" ></i>
                                    </Link>
                                    <span>

                                        <PaginationControl
                                            page={meta?.pageNumber + 1}
                                            between={3}
                                            total={meta?.total}
                                            limit={meta.pageSize}
                                            changePage={(page) => {
                                                onPageChange(page - 1)
                                            }}
                                            ellipsis={1}
                                        />
                                    </span>
                                    <Link
                                        className="paginate_button next"
                                        to="/rewards"
                                        onClick={() =>
                                            onPageChange(meta?.pageCount - 1)
                                        }
                                    >
                                        <i className="fa fa-angle-double-right" ></i>
                                    </Link>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ rewards }) => {
    const { rewardsList, showLoading, meta } = rewards;
    return {
        allRewards: rewardsList,
        loading: showLoading,
        meta: meta,
    };
};

export default connect(mapStateToProps)(AllRewardsTable);
