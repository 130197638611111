import { Tabs } from '../../../helpers/Enums';
import {
  getNFTApi,
  getUsersNFTApi,
  getNFTCollectionByIdApi,
  BuyNFTApi,
  ListNFTApi,  
  updateNFTApi,
} from '../../../services/Marketplace';
import {
  CONFIRMED_GET_NFT,
  LOADING_TOGGLE_ACTION,
  USERS_NFT_MARKETPLACE,
  COLLECTIONBY_ID_NFT_MARKETPLACE,
  BUY_NFT,
  LISTED_NFT,
  EDIT_NFT,
} from './MarketplaceTypes';
import swal from 'sweetalert';

export function getNFTAction(filterData) {
  return fetchDataAction(getNFTApi, CONFIRMED_GET_NFT, filterData);
}

export function getUsersNFTAction(filterData) {
  return fetchDataAction(getUsersNFTApi, USERS_NFT_MARKETPLACE, filterData);
}

export function getCollectionByIdAction(filterData, id) {
  return fetchDataAction(
    getNFTCollectionByIdApi,
    COLLECTIONBY_ID_NFT_MARKETPLACE,
    filterData,
    id
  );
}

export function ListNFTAction(id, navigate,data={}) {
  return (dispatch) => {
    dispatch(loadingToggleAction(true));

    ListNFTApi(id,data)
      .then((response) => {
        dispatch({
          type: LISTED_NFT,
          payload: response.data.data,
        });
        dispatch(loadingToggleAction(false));
        let successMessage;
        if (data && data.price !== undefined) {
          successMessage = 'NFT Listed successfully';
        } else {
          successMessage = 'NFT Unlisted successfully';
        }        swal({
          title: 'Success',
          text: successMessage,
          icon: 'success',
        }).then((ok) => {
          if (ok) {
            navigate('/marketplace', { state: { Tab: Tabs.TAB2 } });
          }
        });
      })
      .catch((error) => {
        dispatch(loadingToggleAction(true));

        const responeError = error?.response?.data?.message;
        swal('Error', `${responeError}`, 'error');
      });
  };
}
export function BuyNFTAction(id, navigate) {
  return (dispatch) => {
    BuyNFTApi(id)
      .then((response) => {
        dispatch({
          type: BUY_NFT,
          payload: response.data.data,
        });
        
        swal({
          title: 'Success',
          text: 'NFT purchased successfully',
          icon: 'success',
        }).then((ok) => {
          if (ok) {
            // Navigate to the dashboard
            navigate('/marketplace', { state: { Tab: Tabs.TAB2 } });
          }
        });
      })
      .catch((error) => {
        const responeError = error?.response?.data?.message;
        swal('Error', `${responeError}`, 'error');
      });
  };
} 

export function updateNFTAction(id,data, navigate) {
  return (dispatch) => {
    dispatch(loadingToggleAction(true));

    updateNFTApi(data, id).then((response) => {
      dispatch({
        type: EDIT_NFT,
        payload: response.data.data,
      });
      dispatch(loadingToggleAction(true));

      swal({
        title: 'Success',
        text: 'NFT Updated successfully',
        icon: 'success',
      }).then((ok) => {
        if (ok) {
          // Navigate to the dashboard
          navigate('/marketplace', { state: { Tab: Tabs.TAB2 } });
        }
      });
    })
      .catch((error) => {
        dispatch(loadingToggleAction(false));

        const responeError = error?.response?.data?.message;
        swal('Error', `${responeError}`, 'error');
      });

  };
}


export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
//generic function for Get API's
function fetchDataAction(apiFunction, actionType, filterData, id) {
  return (dispatch) => {
    apiFunction(filterData, id)
      .then((response) => {
        dispatch({
          type: actionType,
          payload: {
            ...response.data, meta: {
              ...response?.data?.data?.meta,
              sortBy: filterData?.sortBy
            }
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: actionType,
          payload: {
            data: [],
          },
        });
      });
  };
}
