
import { toastError } from '../../../services/ToastService';
import { getTokens } from '../../../services/TokensService';
import {
    CONFIRMED_GET_TOKENS,
    FAILED_GET_TOKENS,
    LOADING_TOGGLE_ACTION
} from './TokenTypes';





export function getTokensAction() {
    return (dispatch) => {
        getTokens()
            .then((response) => {
                dispatch({
                    type: CONFIRMED_GET_TOKENS,
                    payload: response.data.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FAILED_GET_TOKENS,
                    payload: error?.response?.data?.message
                });
                toastError(error?.response?.data?.message)
            });
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}