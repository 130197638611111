import { Button, Modal } from 'react-bootstrap';
import useAllToken from '../../../../hooks/useAllToken';
import { customDropDownMenuStyles } from '../../../../helpers/styles';
import Select from 'react-select';
import { useState } from 'react';
import { toastError } from '../../../../services/ToastService';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ListNFTAction } from '../../../../store/actions/marketplace/NFTAction';


const NFTListing = ({openTokenModal,setOpenTokenModal,id}) => {
    const isLoading = useSelector(state => state.marketplace.showLoading);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { allTokens, isTokenLoading } = useAllToken();
    const [selectedToken, setToken] = useState(null);
    const[selectedPrice,setSelectedPrice]=useState(null)
    const onChangeToken = async (token) => {
        setToken(token)
    }
    const nftListed=async()=>{
        if (!selectedToken || !selectedPrice ) {
            toastError('All fields required.')
            return
        }
        else{
            let listedObj = {
                price:selectedPrice,
                tokenId:selectedToken?.id,
            }
            dispatch(ListNFTAction(id, navigate,listedObj))
            
        }
    }
  return (
    <>
          <Modal className="fade" show={openTokenModal} centered="true">
              <Modal.Header>
                  <Modal.Title>Nft Listing</Modal.Title>
                  <Button
                      onClick={() => setOpenTokenModal(false)}
                      variant=""
                      className="btn-close"
                  ></Button>
              </Modal.Header>
              <Modal.Body>
                  <form>
                      <div className="row g-3 mb-3">
                          <div className="col-xl-12">
                              <label className="form-label">
                                  Price
                                  <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                  <input type="number" min='0' className="form-control w-100" onChange={(e) => setSelectedPrice(e.target.value)}/>
                              </div>
                          </div>
                          <div className="col-xl-12">
                              <label className="form-label">
                                  Token
                                  <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                  <Select
                                      className="custom-react-select mb-xl-0 mb-3 w-100 z-index-9"
                                      options={allTokens}
                                      defaultValue={selectedToken}
                                      isSearchable={true}
                                      placeholder="Select Token"
                                      isDisabled={isTokenLoading}
                                      isLoading={isTokenLoading}
                                      closeMenuOnSelect={true}
                                      closeMenuOnScroll={false}
                                      styles={customDropDownMenuStyles}
                                      onChange={onChangeToken}
                                  />
                              </div>
                          </div>
                      </div>

                      <div className="row g-3 text-center">
                          <div className="col-xl-12">
                              <button
                                  type="button"
                                  className="btn flex-fill btn-primary py-2 fs-5 text-uppercase px-5"
                                  onClick={nftListed}
                              >
                                  {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'List Nft'}

                                  
                              </button>
                          </div>
                      </div>
                  </form>
              </Modal.Body>
          </Modal>
      
    </>
  )
}

export default NFTListing
